import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TrollKickEventPayload } from '@/view/hooks/live-events/liveEvents.interface';
import { Customer } from '@/view/context/LotwProvider';
import { RootState } from '@/view/state/store';

export const initialState: {
  avatarUrl: string;
  email: string;
  id: number;
  isGuest: boolean;
  name: string;
  isBlocked: boolean;
} = {
  avatarUrl: '',
  email: '',
  id: 0,
  isGuest: true,
  name: '',
  isBlocked: false,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<Customer>) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.isGuest = action.payload.isGuest;
      state.name = action.payload.name;
      state.avatarUrl = action.payload.avatarUrl;
    },
    setIsUserBlocked: (state, action: PayloadAction<boolean>) => {
      state.isBlocked = action.payload;
    },
    trollHasBeenKicked: (
      state,
      action: PayloadAction<TrollKickEventPayload>
    ) => {
      if (action.payload.user_id !== state.id) {
        return;
      }

      state.isBlocked = true;
    },
  },
});

export const { setIsUserBlocked, trollHasBeenKicked, setCustomer } =
  customerSlice.actions;

export const selectIsUserBlocked = (state: RootState) =>
  state.customer.isBlocked;
export const selectCustomer = (state: RootState) => state.customer;
