import React, { FC } from 'react';
import useConnectLiveEvents from '@/view/hooks/live-events/useConnectLiveEvents';
import { useAppDispatch, useAppSelector } from '@/view/state/store';
import { selectLotwConfig } from '@/view/state/lotwConfig/lotwConfigSlice';
import { usePresenceChannel } from '@harelpls/use-pusher';
import { setIsLive } from '@/view/state/misc/miscSlice';
import { SkeletonLoader } from '@/view/ui/components/organisms/SkeletonLoader';

export const InitLiveEvents: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const { shopId, dataProvider } = useAppSelector(selectLotwConfig);
  const presenceChannelName =
    dataProvider === 'marketplace'
      ? `presence-marketplace-${shopId}`
      : `presence-LiveOnTheWebSale-${shopId}`;

  // Hook up live events to Redux
  // Must only every use this in one place in the app, or you
  // will get multiple dispatches
  useConnectLiveEvents();

  // Some decisions in Redux are made based on whether we are
  // watching a live stream or a replay
  dispatch(setIsLive(true));

  // Identify this client as a "web" user for viewer calculations
  // See CalculateCurrentLiveViewers.php:61
  const presenceChannel = usePresenceChannel(presenceChannelName);

  if (presenceChannel.myID === undefined) {
    return <SkeletonLoader />;
  }

  return <>{children}</>;
};
