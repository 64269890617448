import React, { FC, HTMLAttributes, useEffect } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import cn from 'classnames';
import { FaVolumeMute } from 'react-icons/fa';
import { isAndroid, isIOS } from '@/view/ui/Live/helpers/platform';
import { useStream } from '@/view/hooks/useStream';

interface VideoPlayerPropsInferface extends HTMLAttributes<HTMLVideoElement> {
  videoJsOptions: videojs.PlayerOptions;
  timestamp?: number;
  tryToAutoplay?: boolean;
  hidePlayButton?: boolean;
}

const VideoPlayer: FC<VideoPlayerPropsInferface> = ({
  videoJsOptions,
  timestamp,
  tryToAutoplay = false,
  hidePlayButton = false,
  ...videoProps
}) => {
  const { isPlaying, setIsPlaying, isMuted, setIsMuted } = useStream();

  const videoElementRef = React.useRef<HTMLVideoElement | null>(null);
  const videoPlayerRef = React.useRef<VideoJsPlayer | null>(null);

  useEffect(() => {
    const videoElement = videoElementRef.current;
    const videoPlayer = videoPlayerRef.current;

    if (!videoPlayer && videoElement) {
      const videoJsInstance = videojs(videoElement, {
        autoplay: true,
        muted: isMuted,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        playsinline: true, // For mobile Safari
        retryOnError: true,
        controls: true,
        controlBar: {
          volumePanel: true,
          fullscreenToggle: false,
          pictureInPictureToggle: false,
          currentTimeDisplay: true,
          remainingTimeDisplay: false,
          playToggle: !hidePlayButton,
          captionsButton: false,
        },
        // Disable the "CC" button on Safari
        html5: { nativeTextTracks: false },
        ...videoJsOptions,
      });

      videoJsInstance.ready(() => {
        videoJsInstance.on('volumechange', () => {
          setIsMuted(!!videoPlayerRef?.current?.muted());
        });

        videoJsInstance.on('play', () => {
          setIsPlaying(true);
        });

        videoJsInstance.on('pause', () => {
          setIsPlaying(false);
        });

        if (tryToAutoplay) {
          // Programmatic autoplay
          const promise = videoJsInstance.play();

          if (promise !== undefined) {
            promise.catch((e) => {
              console.error('Unable to autoplay', e);
            });
          }
        }
      });

      videoPlayerRef.current = videoJsInstance;
    }
  }, [
    hidePlayButton,
    isMuted,
    setIsMuted,
    setIsPlaying,
    tryToAutoplay,
    videoJsOptions,
  ]);

  useEffect(() => {
    if (videoPlayerRef.current && timestamp !== undefined) {
      videoPlayerRef.current.currentTime(timestamp);
    }
  }, [timestamp]);

  useEffect(() => {
    return () => {
      videoPlayerRef.current?.dispose();
    };
  }, []);

  const handleUnmute = () => {
    videoPlayerRef?.current?.muted(false);
  };

  const isMobileDevice = isIOS() || isAndroid();

  return (
    <>
      <div data-vjs-player="true">
        <video
          ref={videoElementRef}
          data-setup='{ "inactivityTimeout": 0 }'
          {...videoProps}
          className={cn(
            'video-js vjs-big-play-centered vjs-tech',
            videoProps.className
          )}
          style={{ height: '100%' }}
        />
      </div>

      {isPlaying && isMuted && (
        <div
          className={
            'lotw-tw-flex lotw-tw-items-center lotw-tw-justify-center lotw-tw-absolute lotw-tw-inset-0 lotw-tw-pointer-events-none lotw-tw-text-white'
          }
        >
          <button
            className={
              'lotw-tw-flex lotw-tw-px-5 lotw-tw-py-4 lotw-tw-bg-black lotw-tw-bg-opacity-40 lotw-tw-text-base lotw-tw-font-semibold lotw-tw-pointer-events-auto lotw-tw-rounded-lg lotw-tw-relative lotw-tw-bottom-8 lotw-tw-items-center'
            }
            onClick={handleUnmute}
          >
            <FaVolumeMute size={'24px'} />{' '}
            <span className={'lotw-tw-ml-2'}>
              {isMobileDevice ? 'Tap' : 'Click'} to unmute
            </span>
          </button>
        </div>
      )}
    </>
  );
};

export default VideoPlayer;
