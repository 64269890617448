import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import removeMd from 'remove-markdown';
import styles from './ProductDetail.module.css';
import { v4 } from 'uuid';
import { InventoryItem, isVideo, Product } from '@/domain/entities/product';
import { ImageWithFallback } from '@/view/ui/components/atoms/ImageWithFallback';
import { VariantSelector } from '@/view/ui/components/organisms/VariantSelector/VariantSelector';
import { MediaGallery } from '@/view/ui/components/molecules/MediaGallery';
import QuantityField from '@/view/ui/components/molecules/QuantityField';
import Button from '@/view/ui/components/atoms/Button';
import { moneyFormat } from '@/utils/math';
import {
  AddToCartDataStructure,
  DefaultAddToCartDataStructure,
  NoVariantAddToCartDataStructure,
} from '@/view/context/LotwProvider';
import usePrice from '@/view/hooks/usePrice';

import {
  PayPalMessages,
  SCRIPT_LOADING_STATE,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import { useAppSelector } from '@/view/state/store';
import { selectLotwConfig } from '@/view/state/lotwConfig/lotwConfigSlice';
import SezzleIcon from '@/view/ui/assets/SezzleIcon';
import {
  buildCtaText,
  getIsCtaDisabled,
} from '@/view/ui/Product/helpers/productDetailHelpers';
import { Omit } from '@reduxjs/toolkit/dist/tsHelpers';

const GIFT_CARD_PRODUCT_TYPE = 'GIFT_CARD';

/**
 * When the component mounts, dispatch to tell PayPalScriptProvider to load
 */
const PayPalPayments = ({ amount }: { amount: number | string }) => {
  const [{ isPending }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: 'setLoadingStatus',
      value: SCRIPT_LOADING_STATE.PENDING,
    });
  }, [dispatch]);

  return (
    <>
      {isPending ? (
        <p className="lotw-tw-m-0 text-grey text-tiny lg:text-tiny-lg">
          Loading
        </p>
      ) : null}
      <PayPalMessages amount={amount} forceReRender={[amount]} />
    </>
  );
};

const SezzlePayments = ({ amount }: { amount: number }) => (
  <p
    className="lotw-tw-m-0 lotw-tw-text-grey lotw-tw-text-xs"
    data-testid={'sezzle-content'}
  >
    or 4 interest-free payments of {usePrice(Math.round((amount * 100) / 4))}{' '}
    with <SezzleIcon />
  </p>
);

interface ProductDetailProps {
  product: Product;
  soldText?: string;
  onAdd: (
    params: Omit<AddToCartDataStructure, 'streamId' | 'isReplay'>
  ) => void;
  isReadOnly?: boolean;
  isAddingToCart?: boolean;
}

const ProductDetail: React.FC<ProductDetailProps> = ({
  product,
  soldText,
  onAdd,
  isReadOnly,
  isAddingToCart,
}) => {
  const [quantityDesired, setQuantityDesired] = useState(1);
  const [selectedVariant, setSelectedVariant] = useState<
    InventoryItem | undefined
  >();

  useEffect(() => {
    setQuantityDesired(1);
  }, [selectedVariant]);

  const images = useMemo(
    () =>
      product.extraMedia
        .filter((mediaItem) => !isVideo(mediaItem))
        .map((image) => {
          return {
            id: v4(),
            src: image.mediaUrl,
            alt: product.name,
          };
        }),
    [product.extraMedia, product.name]
  );

  const videos = useMemo(
    () =>
      product.videos.map((video) => {
        return {
          id: v4(),
          videoSrc: video.mediaUrl,
          src: video.thumbnailUrl,
          alt: product.name,
        };
      }),
    [product.videos, product.name]
  );

  const media = useMemo(() => [...videos, ...images], [videos, images]);

  const handleChooseVariant = useCallback(
    (item?: InventoryItem) => {
      setSelectedVariant(item);
    },
    [setSelectedVariant]
  );

  const handleAdd = async () => {
    if (areVariantsDisabled) {
      const ds: Omit<NoVariantAddToCartDataStructure, 'streamId' | 'isReplay'> =
        {
          type: 'noVariant' as const,
          externalId: product.externalId ?? '',
          productId: product.id,
          productName: product.name,
          productPath: product.path || '',
        };

      onAdd(ds);

      return;
    }

    if (typeof selectedVariant?.id !== 'number') {
      throw new Error('No variant found');
    } else {
      if (!selectedVariant) return;

      const ds: Omit<DefaultAddToCartDataStructure, 'streamId' | 'isReplay'> = {
        type: 'default' as const,
        externalId: product.externalId ?? '',
        id: selectedVariant.id,
        isGiftCard: product.type === GIFT_CARD_PRODUCT_TYPE,
        priceCents: selectedVariant.price * 100,
        productId: product.id,
        productName: product.name,
        productPath: product.path || '',
        quantityDesired,
        quantityAvailable: selectedVariant.quantity,
      };

      onAdd(ds);
    }
  };

  const {
    areVariantsDisabled,
    paypalConfig,
    usesSezzlePayments,
    hideAvailableStockCount,
    productCtaText,
  } = useAppSelector(selectLotwConfig);

  const handleQuantityChange = useCallback((num: number) => {
    setQuantityDesired(num);
  }, []);

  const buttonText = buildCtaText({
    selectedVariant,
    isAddingToCart,
    productCtaText,
    areVariantsDisabled,
  });

  return (
    <div className={'lotw-tw-absolute lotw-tw-inset-0 lotw-tw-mt-6'}>
      <div
        className={
          'lotw-tw-absolute lotw-tw-inset-0 lotw-tw-overflow-auto lotw-tw-text-sm lotw-tw-pb-8'
        }
      >
        <div className="lotw-tw-flex lotw-tw-justify-between">
          <ImageWithFallback
            src={product.thumbnail}
            alt={product.name}
            className="lotw-tw-rounded-md lotw-tw-object-cover"
            style={{ height: 65, width: 65 }}
          />

          <div className="lotw-tw-flex-1 lotw-tw-mx-2">
            <h2 className="lotw-tw-text-black lotw-tw-text-xl">
              {product.name}
            </h2>
            {soldText && (
              <div className="lotw-tw-text-sm">{removeMd(soldText)}</div>
            )}
          </div>
        </div>

        <hr className="lotw-tw-my-4" />

        <div className="lotw-tw-mt-4">
          <MediaGallery media={media} />
        </div>

        <div className="lotw-tw-mt-4">
          <span className="text-subtitle lg:text-subtitle-lg">
            {moneyFormat(selectedVariant?.price)}
          </span>
        </div>

        {paypalConfig && selectedVariant && (
          <div className="lotw-tw-mt-2" data-testid={'paypal-content'}>
            <PayPalPayments amount={selectedVariant.price} />
          </div>
        )}

        {usesSezzlePayments && selectedVariant && (
          <div className="lotw-tw-mt-2">
            <SezzlePayments amount={selectedVariant.price} />
          </div>
        )}

        <div className="lotw-tw-mt-4">
          {!areVariantsDisabled && (
            <VariantSelector
              hideAvailableStockCount={hideAvailableStockCount}
              product={product}
              onChoose={handleChooseVariant}
            />
          )}
        </div>

        {!!selectedVariant?.quantity && (
          <div className="lotw-tw-mt-4">
            <h4 className={styles.label}>Quantity</h4>
            <QuantityField
              min={1}
              max={selectedVariant?.quantity}
              onChange={handleQuantityChange}
            />
          </div>
        )}

        <div className="lotw-tw-mt-4">
          <h4 className={styles.label}>Description</h4>
          <div
            className="lotw-tw-mt-2 rte prose"
            dangerouslySetInnerHTML={{ __html: product.storeDescription }}
          />
        </div>
      </div>

      <div className="lotw-tw-absolute lotw-tw-bottom-4 lotw-tw-text-center lotw-tw-w-full lotw-tw-px-6 lotw-tw-z-20">
        <Button
          primary
          fullWidth
          onClick={handleAdd}
          disabled={getIsCtaDisabled({
            areVariantsDisabled,
            isReadOnly,
            selectedVariant,
            isAddingToCart,
          })}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export { ProductDetail };
