import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit';
import { RootState } from '@/view/state/store';
import { getContainerInstance } from '@/container';
import { Stream } from '@/domain/entities/stream';

export const initialState: {
  isLoading: boolean;
  stream: Stream | undefined;
  error: SerializedError | undefined;
  isPlaying: boolean;
  isMuted: boolean;
  isFullScreen: boolean;
} = {
  isLoading: false,
  stream: undefined,
  error: undefined,
  isPlaying: true,
  isMuted: true,
  isFullScreen: false,
};

export const getStream = createAsyncThunk(
  'useCases/stream/getStream',
  async (id: number) => {
    return getContainerInstance().useCases.getStream(id);
  }
);

export const streamSlice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    clearStream: (state) => {
      state.stream = undefined;
    },
    setIsPlaying: (state, action: { payload: boolean }) => {
      state.isPlaying = action.payload;
    },
    setIsMuted: (state, action: { payload: boolean }) => {
      state.isMuted = action.payload;
    },
    setIsFullScreen: (state, action: { payload: boolean }) => {
      state.isFullScreen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStream.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getStream.fulfilled, (state, action) => {
      state.isLoading = false;
      state.stream = action.payload;
      state.error = undefined;
    });

    builder.addCase(getStream.rejected, (state, action) => {
      state.isLoading = false;
      state.stream = undefined;
      state.error = action.error;
    });
  },
});

export const { clearStream, setIsPlaying, setIsMuted, setIsFullScreen } =
  streamSlice.actions;
export const selectStream = (state: RootState) => state.stream;
