import axios from 'axios';

interface HttpService {
  get<T>(x: { url: string }): Promise<T>;
}

const makeHttpService = ({ baseUrl }: { baseUrl: string }): HttpService => {
  const axiosInstance = axios.create({ baseURL: baseUrl });

  async function get<T>({ url }: { url: string }): Promise<T> {
    const response = await axiosInstance.get(url);
    return response.data;
  }

  return Object.freeze({
    get,
  });
};

export { makeHttpService, HttpService };
