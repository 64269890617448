import { Stream } from '@/domain/entities/stream';
import { Replay } from '@/domain/entities/replay';

const chooseStreamConnection = ({
  selectedStream,
  isLiveHappening,
  isSelectedStreamLive,
  autoConnectToLive,
}: {
  selectedStream: Stream | undefined;
  isLiveHappening: boolean;
  isSelectedStreamLive: boolean;
  autoConnectToLive: boolean;
}): {
  shouldConnectToLive: boolean;
  streamToConnectTo: Stream | Replay | undefined;
} => {
  // Connect to live if:
  // 1. The selected stream is live
  if (selectedStream && isSelectedStreamLive) {
    return {
      shouldConnectToLive: true,
      streamToConnectTo: undefined,
    };
  }
  // 2. A stream is not selected, a live is happening, and autoConnectToLive is true
  if (!selectedStream && isLiveHappening && autoConnectToLive) {
    return {
      shouldConnectToLive: true,
      streamToConnectTo: undefined,
    };
  }

  // Connect to chosen replay if:
  // 1. There is a selected stream
  // 2. The selected stream is not live
  if (selectedStream && !isSelectedStreamLive) {
    return {
      shouldConnectToLive: false,
      streamToConnectTo: selectedStream,
    };
  }

  return {
    shouldConnectToLive: false,
    streamToConnectTo: undefined,
  };
};

export { chooseStreamConnection };
