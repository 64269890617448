import React, { FC } from 'react';
import { LiveProductInfo } from './LiveProductInfo';
import { Product } from '@/domain/entities/product';
import SectionHeading from '@/view/ui/components/atoms/SectionHeading';

export interface MostRecentlyShownProps {
  products: Product[];
  onViewProduct: (product: Product) => void;
}

export const MostRecentlyShown: FC<MostRecentlyShownProps> = ({
  products,
  onViewProduct,
}) => {
  return (
    <div className="lotw-tw-h-full lotw-tw-flex lotw-tw-flex-col lotw-tw-justify-items-stretch">
      <SectionHeading light>Most Recently Shown</SectionHeading>

      <div className="lotw-tw-flex-1 lotw-tw-relative">
        <div className="lotw-tw-absolute lotw-tw-top-0 lotw-tw-right-0 lotw-tw-bottom-0 lotw-tw-left-0 lotw-tw-overflow-auto">
          {products.map((product, key) => (
            <div
              key={`${product.uniqueId}${key}`}
              className="most-recently-shown__product-wrapper"
            >
              <LiveProductInfo
                product={product}
                onViewProduct={onViewProduct}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
