import { HttpService } from '@/infra/services/http/httpService';
import { Comment, CommentRepository } from '@/domain/entities/comment';
import { Reaction, ReactionRepository } from '@/domain/entities/reaction';
import { v4 } from 'uuid';

export interface CommentEventFromWebstoreApi {
  comment: string;
  user_name: string;
  user_image_url: string;
  type: string;
  shown_at: number;
}

export interface ReactionEventFromWebstoreApi {
  count: number;
  duration: number;
  shown_at: number;
}

export interface LiveEventsFromWebstoreApi {
  has_more: boolean;
  comments: CommentEventFromWebstoreApi[];
  reactions: ReactionEventFromWebstoreApi[];
}

const makeMarketplaceLiveEventsRepository = ({
  httpService,
  shopId,
}: {
  httpService: HttpService;
  shopId: string;
}) => {
  let hasFetchedAll = false;
  let commentEventsFromWebstoreApi: CommentEventFromWebstoreApi[] = [];
  let reactionEventsFromWebstoreApi: ReactionEventFromWebstoreApi[] = [];

  // For now, we just keep fetching events until we have them all in memory
  const fetchAll = async (streamId: number, shownAfter?: number) => {
    if (hasFetchedAll) return;

    let url = `/${shopId}/streams/${streamId}/events`;

    if (shownAfter) {
      url = `${url}?shown_after=${shownAfter}`;
    }

    const batch = await httpService.get<LiveEventsFromWebstoreApi>({ url });

    commentEventsFromWebstoreApi = commentEventsFromWebstoreApi.concat(
      batch.comments
    );
    reactionEventsFromWebstoreApi = reactionEventsFromWebstoreApi.concat(
      batch.reactions
    );

    if (batch.has_more) {
      const lastComment = batch.comments[batch.comments.length - 1];

      await fetchAll(streamId, lastComment.shown_at);
    }

    hasFetchedAll = true;
  };

  const commentRepo: CommentRepository = {
    get: async (streamId) => {
      await fetchAll(streamId);
      return makeCommentsFromCommentEventsFromWebstoreApi(
        commentEventsFromWebstoreApi
      );
    },
  };

  const reactionRepo: ReactionRepository = {
    get: async (streamId) => {
      await fetchAll(streamId);
      return makeReactionsFromReactionEventsFromWebstoreApi(
        reactionEventsFromWebstoreApi
      );
    },
  };

  return Object.freeze({
    commentRepo,
    reactionRepo,
  });
};

export { makeMarketplaceLiveEventsRepository };

export const makeCommentsFromCommentEventsFromWebstoreApi = (
  commentEventsFromWebstoreApi: CommentEventFromWebstoreApi[]
): Comment[] => {
  return commentEventsFromWebstoreApi.map((c) => ({
    id: v4(),
    comment: c.comment,
    shownAt: c.shown_at,
    type: c.type,
    userImageUrl: c.user_image_url,
    userName: c.user_name,
  }));
};

export const makeReactionsFromReactionEventsFromWebstoreApi = (
  reactionsEventsFromWebstoreApi: ReactionEventFromWebstoreApi[]
): Reaction[] => {
  return reactionsEventsFromWebstoreApi.map((r) => ({
    id: v4(),
    count: r.count,
    duration: r.duration,
    shownAt: r.shown_at,
  }));
};
