import { uniqBy } from 'lodash';
import { InventoryItem } from '@/domain/entities/product';

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function findInventoryItem({
  inventoryItems,
  selectedSize = '',
  selectedColor = '',
}: {
  inventoryItems: InventoryItem[];
  selectedSize?: string;
  selectedColor?: string;
}): InventoryItem | undefined {
  // If there are no items, return undefined
  if (!inventoryItems.length) return;

  // If there's only one thing, we found it!
  if (
    inventoryItems.length === 1 &&
    !inventoryItems[0].size &&
    !inventoryItems[0].color
  ) {
    return inventoryItems[0];
  }

  // Otherwise, there are choices to make. If we don't have selections, return undefined.
  if (!selectedSize && !selectedColor) return;

  const foundInThisSize = inventoryItems.filter(
    (ii) => selectedSize && selectedSize === ii.size
  );

  const foundInThisColor = inventoryItems.filter(
    (ii) => selectedColor && selectedColor === ii.color
  );

  // If we didn't find anything, return undefined
  if (!foundInThisColor.length && !foundInThisSize.length) return undefined;

  const foundInThisSizeAndColor = uniqBy(
    [...foundInThisSize, ...foundInThisColor].filter((item) => {
      if (selectedColor && selectedSize) {
        return item.color === selectedColor && item.size === selectedSize;
      }

      if (selectedSize) {
        return item.size === selectedSize;
      }

      if (selectedColor) {
        return item.color === selectedColor;
      }

      return false;
    }),
    'inventory_id'
  );

  // If our choice narrows us down to one thing, we found it!
  if (foundInThisSizeAndColor.length === 1) {
    return foundInThisSizeAndColor[0];
  }
}
