import React from 'react';
import cn from 'classnames';

interface VariantGridProps {
  hideAvailableStockCount: boolean;
  onOptionClick: (option: string) => void;
  optionsArray: [string, { quantity: number; variantExists: boolean }][];
  selectedOption?: string;
}

const VariantGrid: React.FC<VariantGridProps> = ({
  hideAvailableStockCount,
  onOptionClick,
  optionsArray,
  selectedOption,
}) => {
  return (
    <div className="lotw-tw-flex lotw-tw-flex-wrap">
      {optionsArray.map(([title, { quantity, variantExists }]) => {
        return (
          <button
            key={title}
            className={cn(
              'btn btn--white focus:lotw-tw-ring-0 lotw-tw-mr-4 lotw-tw-mb-4',
              {
                'variantSwiper__button--selected': title === selectedOption,
                'variantSwiper__button--disabled': !variantExists,
              }
            )}
            onClick={() => onOptionClick(title)}
            disabled={!variantExists}
            data-cy={`variantButton-${title}`}
            data-testid={`variantButton-${title}`}
          >
            <div className="lotw-tw-text-left">
              <div>{title}</div>
              <div className="lotw-tw-text-xs">
                {variantExists
                  ? quantity > 0
                    ? hideAvailableStockCount
                      ? 'In Stock'
                      : `${quantity} left`
                    : 'Waitlist'
                  : 'Unavailable'}
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
};

export { VariantGrid };
