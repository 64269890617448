import React from 'react';
import { useAppDispatch, useAppSelector } from '@/view/state/store';
import { Chat } from '@/view/ui/Chat/Chat';
import {
  handleComment,
  selectComments,
  selectNonSoldComments,
} from '@/view/state/comment/commentSlice';
import {
  addReaction,
  selectReactions,
} from '@/view/state/reaction/reactionSlice';
import useSendLiveEvents from '@/view/hooks/live-events/useSendLiveEvents';
import {
  EventType,
  ReactionEvent,
} from '@/view/hooks/live-events/liveEvents.interface';
import { selectLotwConfig } from '@/view/state/lotwConfig/lotwConfigSlice';
import { v4 } from 'uuid';
import { selectCustomer } from '@/view/state/customer/customerSlice';
import { dispatchLOTWEvent } from '@/shared-kernel/events';

interface ChatContainerProps {
  expirationTime?: string;
  onShopAll: () => void;
  isInOverlay?: boolean;
  isDisabled?: boolean;
  isMobile?: boolean;
  isLive: boolean;
  isReadOnly: boolean;
}

const ChatLiveContainer: React.FC<ChatContainerProps> = ({
  onShopAll,
  isDisabled,
  isInOverlay,
  isMobile,
  isLive,
  isReadOnly,
}) => {
  const { sendEvent, sendReactionEvent } = useSendLiveEvents();
  const dispatch = useAppDispatch();
  const config = useAppSelector(selectLotwConfig);
  const customer = useAppSelector(selectCustomer);
  const comments = useAppSelector(
    config.showSoldCommentsDuringLives ? selectComments : selectNonSoldComments
  );
  const reactions = useAppSelector(selectReactions);

  const handleNewComment = (commentString: string) => {
    dispatch(
      handleComment({
        comment: {
          comment: commentString,
          id: v4(),
          userImageUrl: customer.avatarUrl,
          userName: customer.name,
        },
        // Only send the comment event if handleComment decides to
        callback: () =>
          sendEvent(EventType.AppComment, {
            commentPayload: {
              comment: commentString,
              username: customer.name,
              user_id: customer.id,
              user_image_url: customer.avatarUrl,
              fromWeb: config.dataProvider !== 'marketplace',
              fromMarketplace: config.dataProvider === 'marketplace',
            },
          }),
      })
    );
  };

  const handleNewReaction = () => {
    const reactionEvent: ReactionEvent = {
      reactionType: 'heart',
      shopId: config.shopId,
      user: {
        userId: customer.id,
        userImageURL: customer.avatarUrl,
        username: customer.name,
      },
    };
    sendReactionEvent(reactionEvent);

    dispatch(addReaction({ id: v4() }));
    dispatchLOTWEvent('Reaction', reactionEvent);
  };

  return (
    <Chat
      comments={comments}
      reactions={reactions}
      onNewComment={handleNewComment}
      isDisabled={isDisabled}
      onShopAll={onShopAll}
      isInOverlay={isInOverlay}
      isMobile={isMobile}
      isLive={isLive}
      areReactionsEnabled={true}
      onLike={handleNewReaction}
      isReadOnly={isReadOnly}
    />
  );
};

export { ChatLiveContainer };
