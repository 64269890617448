import React from 'react';
import cn from 'classnames';
import { CommentOrNotification, isChatNotification } from '@/view/ui/Chat/Chat';
import placeholderAvatar from '@/view/ui/assets/avatarPlaceholder.jpg';
import { ImageWithFallback } from '@/view/ui/components/atoms/ImageWithFallback';

interface ChatCommentProps {
  commentOrNotification: CommentOrNotification;
  isDarkMode?: boolean;
}

const ChatComment: React.FC<ChatCommentProps> = ({
  commentOrNotification,
  isDarkMode,
}) => {
  if (isChatNotification(commentOrNotification)) {
    return (
      <div
        className={cn({
          'chat-comment__notification': true,
          'chat-comment__notification--dark-mode': isDarkMode,
        })}
      >
        {commentOrNotification.element}
      </div>
    );
  } else {
    const comment = commentOrNotification;

    return (
      <div
        key={comment.id}
        className={cn({
          'chat-comment lotw-tw-mt-0 lotw-tw-pb-2': true,
          'chat-comment--reply': commentOrNotification.isReplyToMe,
        })}
      >
        <ImageWithFallback
          src={comment.userImageUrl}
          fallbackSrc={placeholderAvatar}
          alt="User avatar"
          className="chat-comment__avatar"
          width={32}
          height={32}
        />

        <div
          className={cn({
            'chat-comment__content': true,
            'chat-comment__content--dark-mode': isDarkMode,
          })}
        >
          <span>{comment.userName}</span>
          <p>{comment.comment}</p>
        </div>
      </div>
    );
  }
};

export { ChatComment };
