import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const ChatItemSkeletonLoader: React.FC = () => (
  <div className="lotw-tw-flex lotw-tw-flex-1 lotw-tw-mx-4 lotw-tw-my-2">
    <Skeleton circle style={{ width: '30px', height: '30px' }} />
    <div className="lotw-tw-flex-1 lotw-tw-ml-4">
      <Skeleton className="lotw-tw-w-1/3 lotw-tw-h-3" />
      <Skeleton className="lotw-tw-w-2/3 lotw-tw-h-3" />
    </div>
  </div>
);
