import { HttpService } from '@/infra/services/http/httpService';
import {
  StreamFromMarketplaceApiProduct,
  StreamFromMarketplaceApi,
  ExtraMediaFromMarketplaceApi,
  isVideoFromMarketplaceApi,
  VideoFromMarketplaceApi,
} from '@/infra/repositories/stream/marketplace/marketplaceStreamRepository.interface';
import { Stream, StreamRepository } from '@/domain/entities/stream';
import { ExtraMedia, Product, Video } from '@/domain/entities/product';
import {
  buildColorsFromInventory,
  buildSizesFromInventory,
} from '@/infra/repositories/helpers';
import { v4 } from 'uuid';

const makeMarketplaceStreamRepository = ({
  httpService,
  shopId,
}: {
  httpService: HttpService;
  shopId: string;
}): StreamRepository => {
  const get = async (id: number) => {
    const streamFromWebstoreApi =
      await httpService.get<StreamFromMarketplaceApi>({
        url: `${shopId}/streams/${id}`,
      });

    return makeStreamFromStreamFromMarketplaceApi(streamFromWebstoreApi);
  };

  return Object.freeze({
    get,
  });
};

export { makeMarketplaceStreamRepository };

// PRIVATE

// The repository should convert API responses into domain entities
// We should not use the API entities directly
export function makeStreamFromStreamFromMarketplaceApi(
  streamFromWebstoreApi: StreamFromMarketplaceApi
): Stream {
  return {
    animatedThumb: streamFromWebstoreApi.animated_thumb,
    endedAt: streamFromWebstoreApi.ended_at,
    id: streamFromWebstoreApi.id,
    isLive: streamFromWebstoreApi.is_live,
    label: streamFromWebstoreApi.label,
    name: streamFromWebstoreApi.name,
    peakViewers: streamFromWebstoreApi.peak_viewers,
    productCount: streamFromWebstoreApi.product_count,
    sourceThumb: streamFromWebstoreApi.source_thumb,
    sourceUrl: streamFromWebstoreApi.source_url,
    startedAt: streamFromWebstoreApi.started_at,
    products: makeProductsFromStreamFromMarketplaceApiProducts(
      streamFromWebstoreApi.products
    ),
  };
}

export function makeProductsFromStreamFromMarketplaceApiProducts(
  streamFromWebstoreApiProducts: StreamFromMarketplaceApiProduct[]
): Product[] {
  return streamFromWebstoreApiProducts.map(
    makeProductFromStreamFromMarketplaceApiProduct
  );
}

function makeProductFromStreamFromMarketplaceApiProduct(
  p: StreamFromMarketplaceApiProduct
): Product {
  const {
    identifier,
    price_label: priceLabel,
    product_id: id,
    product_name: name,
    quantity,
    thumbnail,
    overlay_text,
    shown_at,
    inventory,
    extra_media,
    description,
    store_description: storeDescription,
  } = p;

  return {
    id,
    type: '', // Could be a problem. We can't know if this is a GIFT_CARD if we don't have type, but it doesn't come from the API (yet)
    name,
    description,
    storeDescription,
    quantity,
    style: '', // Could be a problem.
    priceLabel,
    thumbnail: thumbnail ?? '',
    inventory: inventory.map((inventoryItem) => ({
      ...inventoryItem,
      size: inventoryItem.size || '', // Could be null from API, but we need strings in our domain
      color: inventoryItem.color || '', // Could be null from API, but we need strings in our domain
    })),
    comments: [],
    identifier,
    uniqueId: v4(),
    formattedColors: buildColorsFromInventory(inventory),
    formattedSizes: buildSizesFromInventory(inventory),
    soldText: overlay_text[0],
    shownAt: shown_at,
    extraMedia: makeExtraMediaFromStreamInfoFromWebstoreApiProduct(extra_media),
    videos: [],
  };
}

const makeExtraMediaFromStreamInfoFromWebstoreApiProduct = (
  extraMediaFromMarketplaceApi: ExtraMediaFromMarketplaceApi[]
): ExtraMedia[] => {
  return extraMediaFromMarketplaceApi.map((em) => {
    if (isVideoFromMarketplaceApi(em)) {
      return makeVideoFromVideoFromMarketplaceApi(em);
    } else {
      return {
        mediaUrl: em.media_url,
      };
    }
  });
};

const makeVideoFromVideoFromMarketplaceApi = (
  videoFromMarketplaceApi: VideoFromMarketplaceApi
): Video => {
  return {
    mediaUrl: videoFromMarketplaceApi.media_url,
    mediaType: videoFromMarketplaceApi.media_type,
    thumbnailUrl: videoFromMarketplaceApi.thumbnail_url,
  };
};
