import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/view/state/store';
import { LotwConfig } from '@/view/context/LotwProvider';

export const initialState: LotwConfig = {
  baseUrl: '',
  shopId: '',
  soldKeyword: '',
  replayTimestamp: undefined,
  showSoldCommentsDuringLives: true,
  paypalConfig: undefined,
  usesSezzlePayments: false,
  dataProvider: 'webstore',
  isReadOnly: true,
  hideAvailableStockCount: false,
  areVariantsDisabled: false,
  productCtaText: '',
};

export const lotwConfigSlice = createSlice({
  name: 'lotwConfig',
  initialState,
  reducers: {
    setLotwConfig: (state, action) => {
      state.baseUrl = action.payload.baseUrl;
      state.shopId = action.payload.shopId;
      state.soldKeyword = action.payload.soldKeyword;
      state.replayTimestamp = action.payload.replayTimestamp;
      state.showSoldCommentsDuringLives =
        action.payload.showSoldCommentsDuringLives;
      state.paypalConfig = action.payload.paypalConfig;
      state.usesSezzlePayments = action.payload.usesSezzlePayments;
      state.dataProvider = action.payload.dataProvider;
      state.isReadOnly = action.payload.isReadOnly;
      state.hideAvailableStockCount = action.payload.hideAvailableStockCount;
      state.areVariantsDisabled = action.payload.areVariantsDisabled;
      state.productCtaText = action.payload.productCtaText;
    },
  },
});

export const { setLotwConfig } = lotwConfigSlice.actions;
export const selectLotwConfig = (state: RootState) => state.lotwConfig;
export const selectIsReadOnly = (state: RootState) =>
  state.lotwConfig.isReadOnly || state.customer.isBlocked;
