import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { RootState } from '@/view/state/store';
import { getContainerInstance } from '@/container';
import {
  addReaction as addReactionEntity,
  Reaction,
} from '@/domain/entities/reaction';
import { setStateByTimestamp } from '@/view/state/video/videoSlice';

export const initialState: {
  isLoading: boolean;
  reactions: Reaction[];
  reactionsBeforeTimestamp: Reaction[];
  error: SerializedError | null;
} = {
  isLoading: false,
  reactions: [],
  reactionsBeforeTimestamp: [],
  error: null,
};

export const getAllReactions = createAsyncThunk<
  Reaction[],
  number,
  { state: RootState }
>('useCases/reaction/getReactions', (streamId: number) => {
  const { getAllReactions } = getContainerInstance().useCases;

  return getAllReactions(streamId);
});

export const reactionSlice = createSlice({
  name: 'reaction',
  initialState,
  reducers: {
    addReaction: (state, action: PayloadAction<Reaction>) => {
      state.reactions = addReactionEntity(state.reactions, action.payload);
    },
    setReactions: (state, action: PayloadAction<Reaction[]>) => {
      state.reactions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllReactions.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAllReactions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reactions = action.payload;
      state.error = null;
    });

    builder.addCase(getAllReactions.rejected, (state, action) => {
      state.isLoading = false;
      state.reactions = [];
      state.error = action.error;
    });

    builder.addCase(setStateByTimestamp.fulfilled, (state, action) => {
      state.reactionsBeforeTimestamp = action.payload.reactionsBeforeTimestamp;
    });
  },
});

export const { addReaction, setReactions } = reactionSlice.actions;
export const selectReactions = (state: RootState) => state.reaction.reactions;
export const selectReactionsBeforeTimestamp = (state: RootState) =>
  state.reaction.reactionsBeforeTimestamp;
