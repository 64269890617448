import { Replay, ReplayRepository } from '@/domain/entities/replay';
import { HttpService } from '@/infra/services/http/httpService';
import {
  GetReplaysResponse,
  ReplayFromWebstoreApi,
} from '@/infra/repositories/replay/webstore/webstoreReplayRepository.interface';

const makeWebstoreReplayRepository = ({
  httpService,
}: {
  httpService: HttpService;
}): ReplayRepository => {
  const get = async (startingAfter?: number) => {
    let url = '/live/replays';

    if (startingAfter) {
      url = `${url}?starting_after=${startingAfter}`;
    }

    const getReplaysResponse = await httpService.get<GetReplaysResponse>({
      url,
    });

    return makeReplaysFromReplaysFromWebstoreApi(getReplaysResponse.data);
  };

  return Object.freeze({
    get,
  });
};

export { makeWebstoreReplayRepository };

// PRIVATE

// The repository should convert API responses into domain entities
// We should not use the API entities directly
export function makeReplaysFromReplaysFromWebstoreApi(
  replaysFromWebstoreApi: ReplayFromWebstoreApi[]
): Replay[] {
  return replaysFromWebstoreApi.map((replay) => {
    return {
      animatedThumb: replay.animated_thumb,
      endedAt: replay.ended_at,
      id: replay.id,
      influencerSlug: replay.influencerSlug,
      isLive: replay.is_live,
      label: replay.label,
      name: replay.name,
      peakViewers: replay.peak_viewers,
      productCount: replay.product_count,
      sourceThumb: replay.source_thumb,
      sourceUrl: replay.source_url,
      startedAt: replay.started_at,
    };
  });
}
