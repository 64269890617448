import { Product } from '@/domain/entities/product';
import { ReactionEvent } from '@/view/hooks/live-events/liveEvents.interface';

type UnsubscribeToLOTWEventFunction = () => void;

function subscribeToLOTWEvent(
  event: 'ViewProduct',
  callback: (data: Product) => void
): UnsubscribeToLOTWEventFunction;
function subscribeToLOTWEvent(
  event: 'SubmitComment',
  callback: (data?: unknown) => void
): UnsubscribeToLOTWEventFunction;
function subscribeToLOTWEvent(
  event: 'Reaction',
  callback: (data?: ReactionEvent) => void
): UnsubscribeToLOTWEventFunction;
function subscribeToLOTWEvent(
  event: 'ConnectToStream',
  callback: (data: number) => void
): UnsubscribeToLOTWEventFunction;
function subscribeToLOTWEvent(
  event: string,
  callback: unknown
): UnsubscribeToLOTWEventFunction {
  const handler = (e: Event) => {
    if (typeof callback !== 'function') return;
    callback((<CustomEvent>e).detail);
  };

  window.addEventListener(prefixEventType(event), handler);

  return () => window.removeEventListener(prefixEventType(event), handler);
}

function dispatchLOTWEvent(event: 'ViewProduct', payload?: Product): void;
function dispatchLOTWEvent(event: 'SubmitComment', payload?: unknown): void;
function dispatchLOTWEvent(event: 'Reaction', payload?: ReactionEvent): void;
function dispatchLOTWEvent(event: 'ConnectToStream', payload?: number): void;
function dispatchLOTWEvent(event: string, payload?: unknown): void {
  window.dispatchEvent(
    new CustomEvent(prefixEventType(event), {
      detail: payload,
    })
  );
}

export { subscribeToLOTWEvent, dispatchLOTWEvent };

// Private Functions

function prefixEventType(key: string) {
  return `lotw-${key}`;
}
