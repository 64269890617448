/* eslint-disable @typescript-eslint/no-explicit-any */

import { backOff } from 'exponential-backoff';

const PUSHER_AUTH_RETRY_ATTEMPTS = 5;

const makePusherAuthorizer = ({
  authUrl,
  onError,
}: {
  authUrl: string;
  onError: () => void;
}) => {
  return (channel: any) => {
    return {
      authorize(socketId: any, callback: any) {
        authWithRetry({
          authUrl,
          socketId,
          channelName: channel.name,
        })
          .then((res) => res.json())
          .then((data) => callback(null, data))
          .catch((err) => {
            console.error(
              `Pusher authentication failed after all retries.`,
              err
            );

            onError();

            callback(new Error(`Error calling auth endpoint: ${err}`), {
              auth: '',
            });
          });
      },
    };
  };
};

export { makePusherAuthorizer };

// PRIVATE

interface AuthWithRetryParams {
  authUrl: string;
  socketId: string;
  channelName: string;
}

function authWithRetry(params: AuthWithRetryParams) {
  return backOff(() => doFetch(params), {
    numOfAttempts: PUSHER_AUTH_RETRY_ATTEMPTS,
    startingDelay: 1000,
    retry: (e: unknown, attemptNumber: number) => {
      console.warn(
        `Pusher authentication attempt ${attemptNumber} failed. ${
          attemptNumber < PUSHER_AUTH_RETRY_ATTEMPTS ? 'Retrying...' : ''
        }`
      );

      return true;
    },
  });
}

function doFetch({ authUrl, socketId, channelName }: AuthWithRetryParams) {
  return fetch(authUrl, {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      socket_id: socketId,
      channel_name: channelName,
    }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error();
    }

    return res;
  });
}
