import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addProduct as addProductEntity,
  AppendedProduct,
  getProductById,
  mergeProduct,
  Product,
  updateProductInProducts,
} from '@/domain/entities/product';
import { RootState } from '@/view/state/store';
import { setStateByTimestamp } from '@/view/state/video/videoSlice';

export const initialState: {
  products: Product[];
  productsBeforeTimestamp: Product[];
  currentProductId: number | undefined;
  selectedProductId: number | undefined;
  isShopAllOpen: boolean;
} = {
  products: [],
  productsBeforeTimestamp: [],
  currentProductId: undefined,
  selectedProductId: undefined,
  isShopAllOpen: false,
};

export const addProduct = createAsyncThunk<
  Product[],
  Product,
  { state: RootState }
>('useCases/product/addProduct', (newProduct, { getState }) => {
  return addProductEntity(getState().product.products, newProduct);
});

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
    },
    setCurrentProductId: (state, action: PayloadAction<number | undefined>) => {
      state.currentProductId = action.payload;
    },
    setSelectedProductId: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.selectedProductId = action.payload;
    },
    setAppendedProduct: (state, action: PayloadAction<AppendedProduct>) => {
      const product = getProductById(state.products, action.payload.id);

      if (!product) {
        return;
      }
      state.products = updateProductInProducts(
        state.products,
        mergeProduct(product, action.payload)
      );
    },
    setIsShopAllOpen: (state, action: PayloadAction<boolean>) => {
      state.isShopAllOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addProduct.fulfilled, (state, action) => {
      state.products = action.payload;
    });
    builder.addCase(setStateByTimestamp.fulfilled, (state, action) => {
      state.productsBeforeTimestamp = action.payload.productsBeforeTimestamp;
    });
  },
});

export const {
  setProducts,
  setCurrentProductId,
  setSelectedProductId,
  setAppendedProduct,
  setIsShopAllOpen,
} = productSlice.actions;
export const selectProducts = (state: RootState) => state.product.products;
export const selectCurrentProduct = (state: RootState) =>
  state.product.products.find((p) => p.id === state.product.currentProductId);
export const selectLatestProductByTimestamp = (state: RootState) =>
  state.product.productsBeforeTimestamp[
    state.product.productsBeforeTimestamp.length - 1
  ];
export const selectSelectedProduct = (state: RootState) =>
  state.product.products.find((p) => p.id === state.product.selectedProductId);
export const selectIsShopAllOpen = (state: RootState) =>
  state.product.isShopAllOpen;
