import { Replay, ReplayRepository } from '@/domain/entities/replay';

interface GetReplays {
  (startingAfter?: number): Promise<Replay[]>;
}

interface MakeGetReplaysDeps {
  replayRepo: ReplayRepository;
}

const makeGetReplays = ({ replayRepo }: MakeGetReplaysDeps): GetReplays => {
  return replayRepo.get;
};

export { makeGetReplays, GetReplays };
