/**
 * Convert value to number between min and max
 * Similar to lodash.clamp, but it supports falsy min and max values.
 */
export function constrain(
  value: number | string,
  min?: number | string,
  max?: number | string
): number {
  value = Number(value);
  min = Number(min);
  max = Number(max);

  if (!isNaN(min) && value < min) {
    return min;
  }

  if (!isNaN(max) && value > max) {
    return max;
  }

  return value;
}

export function moneyFormat(amount?: number): string {
  if (!amount) return '';

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(amount);
}

export function moneyFormatCents(amount?: number): string {
  if (!amount) return '';

  return moneyFormat(amount / 100);
}
