import { Stream, StreamRepository } from '@/domain/entities/stream';

interface GetStream {
  (id: number): Promise<Stream>;
}

interface MakeGetStreamDeps {
  streamRepo: StreamRepository;
}

const makeGetStream = ({ streamRepo }: MakeGetStreamDeps): GetStream => {
  return (id) => streamRepo.get(id);
};

export { makeGetStream, GetStream };
