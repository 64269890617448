import React, { FC } from 'react';
import { Product } from '@/domain/entities/product';
import { LiveProductInfo } from '@/view/ui/Product/LiveProductInfo';
import SectionHeading from '@/view/ui/components/atoms/SectionHeading';

export interface ShowingNowProps {
  currentProduct?: Product;
  onViewProduct: (product: Product) => void;
}

export const ShowingNow: FC<ShowingNowProps> = ({
  currentProduct,
  onViewProduct,
}) => {
  return (
    <>
      <SectionHeading>Showing Now</SectionHeading>
      {currentProduct ? (
        <LiveProductInfo
          product={currentProduct}
          onViewProduct={onViewProduct}
          isPrimary={true}
        />
      ) : (
        <div className="lotw-tw-p-4">No product selected</div>
      )}
    </>
  );
};
