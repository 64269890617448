import { GetStream } from '@/domain/use-cases/stream/getStream';
import { GetComments } from '@/domain/use-cases/comment/getComments';
import { GetReactions } from '@/domain/use-cases/reaction/getReactions';

interface MakeLoadReplayDataDeps {
  getComments: GetComments;
  getReactions: GetReactions;
  getStream: GetStream;
}

const makeLoadReplayData = ({
  getComments,
  getReactions,
  getStream,
}: MakeLoadReplayDataDeps) => {
  return async (streamId: number) => {
    const [comments, reactions, stream] = await Promise.all([
      getComments.getAllComments(streamId),
      getReactions.getAllReactions(streamId),
      getStream(streamId),
    ]);

    return {
      comments,
      reactions,
      products: stream?.products || [],
    };
  };
};

type LoadReplayData = ReturnType<typeof makeLoadReplayData>;

export { makeLoadReplayData, LoadReplayData };
