import React, { FC, useEffect, useRef } from 'react';
import { Live } from './Live';
import { useAppDispatch, useAppSelector } from '@/view/state/store';
import {
  selectLatestProductByTimestamp,
  selectProducts,
  selectSelectedProduct,
  setSelectedProductId,
} from '@/view/state/product/productSlice';
import {
  selectCurrentVideoTime,
  setScrubToTime,
} from '@/view/state/video/videoSlice';
import { Product } from '@/domain/entities/product';
import { selectViewerCount } from '@/view/state/misc/miscSlice';
import { Replay } from '@/domain/entities/replay';
import { useLotw } from '@/view/context/LotwProvider';
import { selectCustomer } from '@/view/state/customer/customerSlice';

interface LiveReplayProps {
  replay: Replay;
}

export const LiveReplay: FC<LiveReplayProps> = ({ replay }) => {
  const streamIdRef = useRef<number>();

  const { onConnectToStream } = useLotw();
  const currentVideoTime = useAppSelector(selectCurrentVideoTime);
  const selectedProduct = useAppSelector(selectSelectedProduct);
  const currentProduct = useAppSelector(selectLatestProductByTimestamp);
  const allProducts = useAppSelector(selectProducts);
  const userCount = useAppSelector(selectViewerCount);
  const { id: customerId } = useAppSelector(selectCustomer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Don't do anything if the stream ID hasn't changed.
    if (replay.id === streamIdRef.current) return;

    streamIdRef.current = replay.id;

    onConnectToStream?.({ streamId: replay.id, isLive: replay.isLive });
  }, [onConnectToStream, replay]);

  const handleViewProduct = (product?: Product): void => {
    dispatch(setSelectedProductId(product?.id));

    // This should never be undefined for replays
    if (product?.shownAt !== undefined) {
      dispatch(setScrubToTime(product.shownAt));
    }
  };

  return (
    <Live
      currentProduct={currentProduct}
      elapsedTimeWhenMounted={currentVideoTime}
      isLive={false}
      onCloseSelectedProduct={handleViewProduct}
      onViewProduct={handleViewProduct}
      allProducts={allProducts}
      selectedProduct={selectedProduct}
      streamUrl={replay.sourceUrl || undefined}
      viewers={userCount}
      userId={customerId}
      heading={replay.name}
    />
  );
};
