import {
  StreamInfo,
  StreamInfoRepository,
} from '@/domain/entities/stream-info';

interface GetStreamInfo {
  (streamId?: number): Promise<StreamInfo | undefined>;
}

interface MakeGetStreamInfoDeps {
  streamInfoRepo: StreamInfoRepository;
}

const makeGetStreamInfo = ({
  streamInfoRepo,
}: MakeGetStreamInfoDeps): GetStreamInfo => {
  return (streamId?: number) => streamInfoRepo.get(streamId);
};

export { makeGetStreamInfo, GetStreamInfo };
