import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { RootState } from '@/view/state/store';
import {
  addComment as addCommentEntity,
  Comment,
  getIsSoldComment,
} from '@/domain/entities/comment';
import {
  setIsShopAllOpen,
  setSelectedProductId,
} from '@/view/state/product/productSlice';
import { getContainerInstance } from '@/container';
import { setStateByTimestamp } from '@/view/state/video/videoSlice';
import { ProductId } from '@/domain/entities/product';

export const initialState: {
  isLoading: boolean;
  comments: Comment[];
  commentsBeforeTimestamp: Comment[];
  error: SerializedError | null;
} = {
  isLoading: false,
  comments: [],
  commentsBeforeTimestamp: [],
  error: null,
};

export const handleComment = createAsyncThunk<
  void,
  { comment: Comment; callback: () => void },
  { state: RootState }
>(
  'useCases/comment/handleComment',
  async ({ comment, callback }, { getState, dispatch }) => {
    const soldKeyword = getState().lotwConfig.soldKeyword;
    const currentProductId = getState().product.currentProductId;
    const allProducts = getState().product.products;

    const { isSoldComment, identifierFromSoldComment } =
      getContainerInstance().useCases.handleComment({
        comment,
        soldKeyword,
      });

    if (isSoldComment) {
      const productIdFromSoldComment = getProductIdFromIdentifier(
        identifierFromSoldComment,
        currentProductId
      );
      dispatch(setSelectedProductId(productIdFromSoldComment));
      dispatch(setIsShopAllOpen(true));
    } else {
      dispatch(addComment(comment));
      callback();
    }

    function getProductIdFromIdentifier(
      identifier: string | undefined,
      currentProductId: ProductId | undefined
    ): ProductId | undefined {
      return identifier
        ? allProducts.find(
            (p) => p.identifier.toLowerCase() === identifier.toLowerCase()
          )?.id
        : currentProductId;
    }
  }
);

export const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    addComment: (state, action: PayloadAction<Comment>) => {
      state.comments = addCommentEntity(state.comments, action.payload);
    },
    setComments: (state, action: PayloadAction<Comment[]>) => {
      state.comments = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setStateByTimestamp.fulfilled, (state, action) => {
      state.commentsBeforeTimestamp = action.payload.commentsBeforeTimestamp;
    });
  },
});

export const { addComment, setComments } = commentSlice.actions;
export const selectComments = (state: RootState) => state.comment.comments;
export const selectNonSoldComments = (state: RootState) =>
  state.comment.comments.filter(
    (comment) => !getIsSoldComment(comment, state.lotwConfig.soldKeyword)
  );
export const selectCommentsBeforeTimestamp = (state: RootState) =>
  state.comment.commentsBeforeTimestamp;
