import React, { FC, useRef } from 'react';
import { Product } from '@/domain/entities/product';
import useElementSize from '@/view/hooks/useElementSize';
import cn from 'classnames';
import { VideoStream } from '@/view/ui/Live/VideoStream';
import { useAppDispatch, useAppSelector } from '@/view/state/store';
import { selectIsReadOnly } from '@/view/state/lotwConfig/lotwConfigSlice';
import { Overlay } from '@/view/ui/Live/Overlay';
import { MostRecentlyShown } from '@/view/ui/Product/MostRecentlyShown';
import { ProductDetailContainer } from '@/view/ui/Product/ProductDetailContainer';
import { LiveShopAll } from '@/view/ui/Product/LiveShopAll';
import { ShowingNow } from '@/view/ui/Live/ShowingNow';
import { ChatContainer } from '@/view/ui/Chat/ChatContainer';
import { selectIsOverlayCleared } from '@/view/state/misc/miscSlice';
import { selectCustomer } from '@/view/state/customer/customerSlice';
import {
  selectIsShopAllOpen,
  setIsShopAllOpen,
} from '@/view/state/product/productSlice';
import { useStream } from '@/view/hooks/useStream';
import { useLotw } from '@/view/context/LotwProvider';

export interface LiveProps {
  currentProduct?: Product;
  elapsedTimeWhenMounted: number;
  heading?: string;
  hideProduct?: boolean;
  isLive: boolean;
  onCloseSelectedProduct: () => void;
  onViewProduct: (product?: Product) => void;
  allProducts: Product[];
  selectedProduct?: Product;
  streamUrl?: string;
  viewers: number;
  userId: number;
}

export const Live: FC<LiveProps> = ({
  heading,
  selectedProduct,
  streamUrl,
  isLive,
  elapsedTimeWhenMounted,
  currentProduct,
  onViewProduct,
  viewers,
  allProducts,
  onCloseSelectedProduct,
  userId,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const dispatch = useAppDispatch();
  const colors = currentProduct?.formattedColors;
  const price = currentProduct?.priceLabel;
  const productImgSrc = currentProduct?.thumbnail;
  const sizes = currentProduct?.formattedSizes;
  const soldText = currentProduct?.soldText;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const { isFullScreen, setIsFullScreen } = useStream();

  const isShopAllOpen = useAppSelector(selectIsShopAllOpen);
  const { isGuest } = useAppSelector(selectCustomer);
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const isOverlayCleared = useAppSelector(selectIsOverlayCleared);
  const { width } = useElementSize(wrapperRef);
  const isMobile = width < 768;
  const isReallyFullScreen = isMobile && isFullScreen; // Only allowed in mobile
  const videoWidth = Math.min(width - 32, 400);
  const { customization } = useLotw();

  const handleBuyClick = () => {
    if (!currentProduct) return;

    dispatch(setIsShopAllOpen(true));

    onViewProduct(currentProduct);
  };

  const handleFullScreenClick = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div
      ref={wrapperRef}
      className={cn('live-component__wrapper', {
        'live-component__wrapper--full-screen': isReallyFullScreen,
      })}
    >
      {heading && (
        <h1
          className={cn(
            'live-component__heading',
            width >= 768 && 'lotw-tw-block'
          )}
        >
          {heading}
        </h1>
      )}

      <div
        className={cn('live-component__constrainer', {
          'live-component__constrainer--full-screen': isReallyFullScreen,
        })}
        style={{
          gridTemplateColumns:
            width > 1200 ? 'auto 1fr 1fr' : width >= 768 ? 'auto 1fr' : 'auto',
        }}
      >
        <div
          className={cn(
            'lotw-tw-w-full lotw-tw-overflow-hidden lotw-tw-relative',
            // eslint-disable-next-line sonarjs/no-duplicate-string
            width >= 400 && 'card--tablet',
            // eslint-disable-next-line sonarjs/no-duplicate-string
            width >= 768 && 'card--desktop',
            {
              'live-component__video-container--full-screen':
                isReallyFullScreen,
            }
          )}
          style={{
            width: isReallyFullScreen || width < 400 ? '100%' : videoWidth,
          }}
        >
          <VideoStream
            streamUrl={streamUrl}
            isFullScreen={isReallyFullScreen}
          />

          <Overlay
            elapsedTimeWhenMounted={elapsedTimeWhenMounted}
            viewers={viewers}
            isLive={isLive}
            soldText={soldText}
            sizes={sizes}
            colors={colors}
            price={price}
            productImgSrc={productImgSrc}
            onBuyClick={handleBuyClick}
            hideProduct={!currentProduct || isOverlayCleared}
            onFullScreenClick={handleFullScreenClick}
            isMobile={isMobile}
          />

          <div
            className={cn(
              'live-component__chat-overlay',
              width >= 1200 && 'lotw-tw-hidden'
            )}
            style={{
              bottom: 29,
            }}
          >
            <ChatContainer
              isDisabled={isGuest}
              onShopAll={() => dispatch(setIsShopAllOpen(true))}
              isInOverlay={true}
              isMobile={isMobile}
              isLive={isLive}
              isReadOnly={isReadOnly}
            />
          </div>

          {isMobile && (
            <div
              className={cn({
                'live-component__shop-all-drawer': true,
                'is-open': isShopAllOpen,
                'is-product-selected': isShopAllOpen && !!selectedProduct,
              })}
            >
              <button
                className="lotw-tw-absolute lotw-tw-top-1 lotw-tw-right-1 lotw-tw-z-10"
                onClick={() => dispatch(setIsShopAllOpen(false))}
                style={{ color: '#999999', backgroundColor: 'white' }}
              >
                {/*<Icons type="close" className="lotw-tw-w-6 lotw-tw-h-6" />*/}
              </button>

              {/* Tricky bug here. Both ProductDetail components cannot run
                at the same time so I have to not mount this one unless
                lotw-tw-visible.
            */}
              {isShopAllOpen && (
                <LiveShopAll
                  userId={userId}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={onViewProduct}
                  allProducts={allProducts}
                  closeShopAll={() => dispatch(setIsShopAllOpen(false))}
                />
              )}
            </div>
          )}
        </div>

        <div
          className={cn(
            'live-component__product-container lotw-tw-relative',
            width >= 768 &&
              'lotw-tw-flex lotw-tw-flex-col lotw-tw-justify-items-stretch'
          )}
        >
          {selectedProduct && (
            <div
              className={cn(
                'live-component__product-detail-container',
                width >= 400 && 'card--tablet',
                width >= 768 && 'card--desktop'
              )}
            >
              <ProductDetailContainer
                userId={userId}
                productId={selectedProduct.id}
                soldText={selectedProduct.soldText}
                onBack={onCloseSelectedProduct}
              />
            </div>
          )}

          {!customization.hideSelectedItem && (
            <div
              className={cn(
                width >= 400 && 'card--tablet',
                width >= 768 && 'card--desktop'
              )}
            >
              <ShowingNow
                currentProduct={currentProduct}
                onViewProduct={onViewProduct}
              />
            </div>
          )}

          <div
            className={cn(
              'lotw-tw-flex-1 lotw-tw-relative',
              width >= 400 && 'card--tablet',
              width >= 768 && 'card--desktop',
              !customization.hideSelectedItem && 'lotw-tw-mt-2'
            )}
          >
            <MostRecentlyShown
              products={allProducts || []}
              onViewProduct={onViewProduct}
            />
          </div>
        </div>

        <div
          className={cn(
            'live-component__chat-container',
            width > 1200 && 'lotw-tw-block lotw-tw-relative lotw-tw-h-full',
            width >= 400 && 'card--tablet',
            width >= 768 && 'card--desktop'
          )}
        >
          <ChatContainer
            isDisabled={isGuest}
            onShopAll={() => dispatch(setIsShopAllOpen(true))}
            isInOverlay={false}
            isMobile={isMobile}
            isLive={isLive}
            isReadOnly={isReadOnly}
          />
        </div>
      </div>
    </div>
  );
};
