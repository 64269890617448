import {
  Comment,
  CommentRepository,
  getIdentifierFromCommentText,
  getIsSoldComment,
} from '@/domain/entities/comment';

interface MakeGetCommentsDeps {
  commentRepo: CommentRepository;
}

const makeGetComments = ({ commentRepo }: MakeGetCommentsDeps) => {
  const getAllComments = async (streamId: number) => {
    return await commentRepo.get(streamId);
  };

  const getCommentsBeforeTimestamp = (
    comments: Comment[],
    timestamp: number
  ) => {
    return comments.filter(
      (comment) => comment.shownAt && comment.shownAt <= timestamp
    );
  };

  const handleComment = ({
    comment,
    soldKeyword,
  }: {
    comment: Comment;
    soldKeyword?: string;
  }) => {
    const isSoldComment = getIsSoldComment(comment, soldKeyword);
    let identifierFromSoldComment: string | undefined = undefined;

    if (isSoldComment) {
      identifierFromSoldComment = getIdentifierFromCommentText(comment.comment);
    }

    return {
      isSoldComment,
      identifierFromSoldComment,
    };
  };

  return {
    getAllComments,
    getCommentsBeforeTimestamp,
    handleComment,
  };
};

type GetComments = ReturnType<typeof makeGetComments>;

export { makeGetComments, GetComments };
