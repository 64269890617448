import React, {
  FC,
  FormEvent,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FaHeart, FaRegClock, FaRegHeart } from 'react-icons/fa';
import cn from 'classnames';
import { useVirtual } from 'react-virtual';
import { MdSend } from 'react-icons/md';
import { Comment } from '@/domain/entities/comment';
import { ChatComment } from '@/view/ui/Chat/ChatComment';
import { Reaction } from '@/domain/entities/reaction';
import SectionHeading from '@/view/ui/components/atoms/SectionHeading';
import { useLotw } from '@/view/context/LotwProvider';
import { dispatchLOTWEvent } from '@/shared-kernel/events';

export interface ChatNotification {
  id: string;
  element: ReactElement;
}

export type CommentOrNotification = Comment | ChatNotification;

export function isChatNotification(
  thing: CommentOrNotification
): thing is ChatNotification {
  return (thing as ChatNotification).element !== undefined;
}

export interface ChatProps {
  comments: CommentOrNotification[];
  // It truly doesn't matter what type reactions are here.
  reactions: Reaction[];
  expirationTime?: string;
  onNewComment?: (comment: string) => void;
  onLike?: () => void;
  onShopAll: () => void;
  isInOverlay?: boolean;
  isDisabled?: boolean;
  isMobile?: boolean;
  isLive: boolean;
  areReactionsEnabled?: boolean;
  disableLoginDestination?: boolean;
  isReadOnly: boolean;
}

export const Chat: FC<ChatProps> = ({
  comments,
  expirationTime,
  onNewComment,
  onLike,
  onShopAll,
  isInOverlay = false,
  isDisabled = false,
  isMobile = false,
  areReactionsEnabled = false,
  isLive,
  reactions,
  disableLoginDestination,
  isReadOnly,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const chatContainerRef = React.useRef<HTMLDivElement>(null);
  const heartsRef = useRef<HTMLDivElement>(null);

  const [commentInput, setCommentInput] = useState('');
  const [followBottom, setFollowBottom] = useState(true);
  const [latestReaction, setLatestReaction] = useState<Reaction>();

  const { onLogin } = useLotw();

  const rowVirtualizer = useVirtual({
    size: comments.length,
    parentRef: chatContainerRef,
    overscan: 50,
  });

  const createHeart = () => {
    const heartDiv = document.createElement('div');
    heartDiv.className = 'heart';

    heartsRef.current?.appendChild(heartDiv);

    setTimeout(() => {
      // Heart dissappears with CSS but I don't want the DOM to fill up
      heartDiv.remove();
    }, 5000);
  };

  const handleCommentChange = (e: FormEvent<HTMLInputElement>) => {
    setCommentInput(e.currentTarget.value);
  };

  const handleCommentSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (!commentInput.trim().length) return;

    onNewComment?.(commentInput);
    setCommentInput('');

    dispatchLOTWEvent('SubmitComment');
  };

  useEffect(() => {
    setLatestReaction((prevLatestReaction) => {
      const newLatestReaction = reactions[reactions.length - 1];

      return prevLatestReaction?.id === newLatestReaction?.id
        ? prevLatestReaction
        : newLatestReaction;
    });
  }, [reactions]);

  useEffect(() => {
    if (!latestReaction) return;
    createHeart();
  }, [latestReaction]);

  useEffect(() => {
    if (followBottom) {
      rowVirtualizer.scrollToOffset(rowVirtualizer.totalSize);
    }
  });

  const handleScroll = (event: SyntheticEvent) => {
    const el = event.currentTarget;

    if (el.scrollHeight - el.scrollTop - 20 <= el.clientHeight) {
      // The user scrolled close to the bottom, so make sure the scroll locks at the bottom.
      // The extra 20px is to catch a user being "close enough" to the bottom for a better experience.
      setFollowBottom(true);
    } else {
      // User scrolled up, so don't follow the bottom.
      setFollowBottom(false);
    }
  };

  const loginPath = `/webstore-login${
    disableLoginDestination ? '' : '?destination=live'
  }`;

  return (
    <div
      className={cn('chat-component__wrapper lotw-tw-text-left', {
        'chat-component__wrapper--dark-mode': isInOverlay,
        'lotw-tw-pb-0': !isLive,
      })}
    >
      {!isInOverlay && <SectionHeading light>Chat</SectionHeading>}

      <div
        className={
          'lotw-tw-flex-1 lotw-tw-flex lotw-tw-flex-col lotw-tw-justify-end lotw-tw-overflow-hidden'
        }
      >
        {!followBottom && (
          <div
            className={cn('lotw-tw-absolute lotw-tw-z-10 lotw-tw-w-full', {
              'lotw-tw-bottom-8': isLive && !isInOverlay,
              'lotw-tw-bottom-7': isLive && isInOverlay,
              'lotw-tw-bottom-6': !isLive && isInOverlay,
              'lotw-tw-bottom-4': !isLive && !isInOverlay,
            })}
          >
            <button
              className={
                'btn btn--primary lotw-tw-rounded-full lotw-tw-px-2 lotw-tw-py-1 lotw-tw-mx-auto lotw-tw-block lotw-tw-shadow-2xl'
              }
              onClick={() => setFollowBottom(true)}
            >
              See newest comments
            </button>
          </div>
        )}

        <div
          className="lotw-tw-overflow-x-hidden lotw-tw-overflow-y-auto"
          ref={chatContainerRef}
          onScroll={handleScroll}
        >
          <ul
            className="lotw-tw-relative lotw-tw-w-full lotw-tw-mb-2"
            style={{
              height: rowVirtualizer.totalSize,
            }}
          >
            {rowVirtualizer.virtualItems.map((virtualRow) => (
              <li
                key={virtualRow.index}
                ref={virtualRow.measureRef}
                className={cn('lotw-tw-w-full lotw-tw-px-2')}
                style={{
                  top: 0,
                  position: 'absolute',
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <ChatComment
                  commentOrNotification={comments[virtualRow.index]}
                  isDarkMode={isInOverlay}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>

      {expirationTime && (
        <div
          className={cn({
            'chat-component__expiration-time-wrapper': true,
            'chat-component__expiration-time-wrapper--dark-mode': isInOverlay,
          })}
        >
          <FaRegClock
            color="#eb5757"
            className="lotw-tw-mr-3"
            style={{ fontSize: 24 }}
          />
          <div className="chat-component__expiration-time">
            Items in your cart expire in {expirationTime}!
          </div>
        </div>
      )}

      {!isLive && isMobile && (
        <button
          className="chat-component__shop-all--replay-mode"
          onClick={onShopAll}
          type="button"
        >
          SHOP ALL
        </button>
      )}

      {isLive && (
        <form
          onSubmit={handleCommentSubmit}
          className="lotw-tw-flex lotw-tw-items-center lotw-tw-p-2 lotw-tw-pt-0 lotw-tw-absolute lotw-tw-right-0 lotw-tw-bottom-0 lotw-tw-left-0"
        >
          {isMobile && (
            <button
              className="chat-component__shop-all"
              onClick={onShopAll}
              type="button"
            >
              SHOP ALL
            </button>
          )}

          {!isReadOnly && (
            <>
              {isDisabled && (
                <div
                  className={cn(
                    'chat-component__input lotw-tw-flex lotw-tw-items-center',
                    {
                      'chat-component__input--dark-mode': isInOverlay,
                    }
                  )}
                >
                  <p>
                    {onLogin ? (
                      <>
                        <button
                          className="lotw-tw-underline"
                          style={{ color: isInOverlay ? 'inherit' : 'blue' }}
                          onClick={onLogin}
                        >
                          Login
                        </button>{' '}
                        to chat.
                      </>
                    ) : (
                      <>
                        <a
                          href={loginPath}
                          className="lotw-tw-underline"
                          style={{ color: isInOverlay ? 'inherit' : 'blue' }}
                        >
                          Login
                        </a>{' '}
                        to chat.
                      </>
                    )}
                  </p>
                </div>
              )}

              {!isDisabled && (
                <input
                  value={commentInput}
                  onChange={handleCommentChange}
                  className={cn({
                    'chat-component__input': true,
                    'chat-component__input--dark-mode': isInOverlay,
                  })}
                  placeholder={'Send a message'}
                  disabled={isDisabled}
                />
              )}

              {!isDisabled && (
                <button
                  type={'submit'}
                  className={cn({
                    'chat-component__send-button': true,
                    'chat-component__send-button--dark-mode': isInOverlay,
                  })}
                >
                  <MdSend />
                </button>
              )}

              {!isDisabled && areReactionsEnabled && (
                <button
                  type="button"
                  className={cn(
                    'chat-component__heart-button lotw-tw-ml-2 lotw-tw-relative',
                    {
                      'chat-component__heart-button--dark-mode': isInOverlay,
                    }
                  )}
                  onClick={() => onLike?.()}
                >
                  {isInOverlay ? (
                    <FaHeart color="#eb5757" />
                  ) : (
                    <FaRegHeart color="white" />
                  )}
                </button>
              )}
            </>
          )}
        </form>
      )}

      {/* pointer-events-none so the button under this is still clickable */}
      <div
        className="hearts lotw-tw-absolute lotw-tw-right-2 lotw-tw-pointer-events-none"
        ref={heartsRef}
      />
    </div>
  );
};
