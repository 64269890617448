import React from 'react';
import { ProductDetail } from './ProductDetail';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { useAppSelector } from '@/view/state/store';
import { selectSelectedProduct } from '@/view/state/product/productSlice';
import { AddToCartDataStructure, useLotw } from '@/view/context/LotwProvider';
import styles from './ProductDetailContainer.module.css';
import { selectIsLive } from '@/view/state/misc/miscSlice';
import { selectStream } from '@/view/state/stream/streamSlice';
import { selectStreamInfo } from '@/view/state/stream-info/streamInfoSlice';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import {
  selectIsReadOnly,
  selectLotwConfig,
} from '@/view/state/lotwConfig/lotwConfigSlice';
import { Omit } from '@reduxjs/toolkit/dist/tsHelpers';

interface ProductDetailContainerProps {
  userId?: number;
  productId: number;
  soldText?: string;
  onBack?: () => void;
  hideHeader?: boolean;
}

const ProductDetailContainer: React.FC<ProductDetailContainerProps> = ({
  userId,
  soldText,
  onBack,
  hideHeader,
}) => {
  const { onAddToCart, isAddingToCart } = useLotw();
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const product = useAppSelector(selectSelectedProduct);
  const isLive = useAppSelector(selectIsLive);
  const { streamInfo } = useAppSelector(selectStreamInfo);
  const { stream } = useAppSelector(selectStream);
  const streamId = isLive ? streamInfo?.id : stream?.id;

  const handleAdd = async (
    params: Omit<AddToCartDataStructure, 'streamId' | 'isReplay'>
  ) => {
    // Should be impossible
    if (!streamId) {
      console.error('No stream found');
      return;
    }

    if (!userId || isReadOnly) {
      console.error('You must sign in to add to cart');
      return;
    }

    // TS is confused because of the union type. Trust me.
    const ds = {
      ...params,
      isReplay: !isLive,
      streamId,
    } as AddToCartDataStructure;

    try {
      onAddToCart?.(ds);

      onBack?.();
    } catch (error) {
      console.error({ message: 'There was an error adding to cart', error });
    }
  };

  const { paypalConfig } = useAppSelector(selectLotwConfig);

  const payPalScriptProviderOptions = {
    'client-id': paypalConfig?.clientId ?? '',
    'merchant-id': paypalConfig?.merchantId ?? '',
    components: 'messages',
  };

  return (
    <div className="lotw-tw-h-full lotw-tw-flex lotw-tw-flex-col lotw-tw-relative">
      {!hideHeader && (
        <div className="lotw-tw-flex-0 lotw-tw-flex lotw-tw-items-center lotw-tw-justify-between">
          <button onClick={onBack}>
            <HiOutlineArrowNarrowLeft className={styles.backButtonIcon} />
            <span className={styles.backButtonLabel}>Back</span>
          </button>

          <h2 className="lotw-tw-text-base lotw-tw-font-normal">
            Product Details
          </h2>

          <div style={{ width: 53 }}>
            {/* EMPTY COLUMN to balance back button */}
          </div>
        </div>
      )}

      <div className="lotw-tw-flex-1">
        {product && (
          <PayPalScriptProvider
            deferLoading={true}
            options={payPalScriptProviderOptions}
          >
            <ProductDetail
              product={product}
              soldText={soldText}
              onAdd={handleAdd}
              isReadOnly={isReadOnly}
              isAddingToCart={isAddingToCart}
            />
          </PayPalScriptProvider>
        )}
      </div>
    </div>
  );
};

export { ProductDetailContainer };
