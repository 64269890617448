import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit';
import { RootState } from '@/view/state/store';
import { getContainerInstance } from '@/container';
import { Replay } from '@/domain/entities/replay';
import { setComments } from '@/view/state/comment/commentSlice';
import { setProducts } from '@/view/state/product/productSlice';
import { setReactions } from '@/view/state/reaction/reactionSlice';

export const initialState: {
  isLoading: boolean;
  replays: Replay[];
  error: SerializedError | null;
} = {
  isLoading: false,
  replays: [],
  error: null,
};

export const getReplays = createAsyncThunk(
  'useCases/replay/getReplays',
  async (startingAfter: number | undefined) => {
    return await getContainerInstance().useCases.getReplays(startingAfter);
  }
);

export const loadReplayData = createAsyncThunk(
  'useCases/replay/loadReplayData',
  async (streamId: number, { dispatch }) => {
    const { comments, reactions, products } =
      await getContainerInstance().useCases.loadReplayData(streamId);

    dispatch(setComments(comments));
    dispatch(setReactions(reactions));
    dispatch(setProducts(products));
  }
);

export const replaySlice = createSlice({
  name: 'replay',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReplays.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getReplays.fulfilled, (state, action) => {
      state.isLoading = false;
      state.replays = action.payload;
      state.error = null;
    });

    builder.addCase(getReplays.rejected, (state, action) => {
      state.isLoading = false;
      state.replays = [];
      state.error = action.error;
    });
  },
});

// export const {} = replaySlice.actions;
export const selectLatestReplay = (state: RootState) => state.replay.replays[0];
