import {
  ExtraMediaFromWebstoreApi,
  StreamInfoFromWebstoreApiProduct,
  StreamInfoFromWebstoreApiProductInventoryItem,
} from '@/infra/repositories/stream-info/webstore/webstoreStreamInfoRepository.interface';

export type LiveEvent =
  | AppCommentEvent
  | ClearProductOverlayEvent
  | CurrentProductEvent
  | UserCountEvent
  | TrollKickEvent
  | AppendCurrentProductEvent;

export type LiveEventPayload =
  | AppCommentEventPayload
  | ClearProductOverlayEventPayload
  | CurrentProductEventPayload
  | UserCountEventPayload
  | TrollKickEventPayload
  | AppendCurrentProductEventPayload;

export enum EventType {
  CurrentProduct = 'current_product',
  AppComment = 'app_comment',
  FacebookComment = 'facebook_comment',
  UserCount = 'user_count',
  ClearProductOverlay = 'clear_product_overlay',
  TrollKick = 'troll_kick',
  AppendCurrentProduct = 'append_current_product', // event fired when there is too much data, this gets chunked on
}

export interface CurrentProductEvent {
  eventType: EventType.CurrentProduct;
  shopId: string;
  payload: CurrentProductEventPayload;
}

export interface CurrentProductEventPayload {
  overlayText: string[];
  productDetails: StreamInfoFromWebstoreApiProduct;
  productIdentifier: string;
}

export interface AppendCurrentProductEvent {
  eventType: EventType.AppendCurrentProduct;
  shopId: string;
  payload: AppendCurrentProductEventPayload;
}

export interface AppendCurrentProductEventPayload {
  appendProductDetails: {
    product_id: number;
    extra_media: ExtraMediaFromWebstoreApi[];
    inventory: StreamInfoFromWebstoreApiProductInventoryItem[];
  };
}

export interface AppCommentEvent {
  eventType: EventType.AppComment | EventType.FacebookComment;
  shopId: string;
  payload: AppCommentEventPayload;
}

export interface AppCommentEventPayload {
  commentPayload: {
    comment: string;
    user_id: number;
    user_image_url: string;
    username: string;
    to_user_id?: number;
    fromWeb: boolean;
    fromMarketplace: boolean;
  };
}

export interface UserCountEvent {
  eventType: EventType.UserCount;
  shopId: string;
  payload: UserCountEventPayload;
}

export interface UserCountEventPayload {
  appUsers: number;
  facebookUsers: number;
  groupUsers: number;
  rawUsers: number;
  userCount: number;
  userCountDisplay: string;
}

export interface ClearProductOverlayEvent {
  eventType: EventType.ClearProductOverlay;
  shopId: string;
  payload: ClearProductOverlayEventPayload;
}

export interface ClearProductOverlayEventPayload {
  clearOverlay: boolean;
}

export interface LiveSaleEndedEvent {
  shopId: string;
  liveSaleId: number;
  endedAt: number;
}

export interface ReactionEvent {
  shopId: string;
  reactionType: 'heart';
  user: {
    username: string;
    userId: number;
    userImageURL: string;
  };
  appVersion?: string;
}

export interface TrollKickEvent {
  eventType: EventType.TrollKick;
  shopId: string;
  payload: TrollKickEventPayload;
}

export interface TrollKickEventPayload {
  user_id: number;
}
