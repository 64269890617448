import { StreamInfoFromMarketplaceApi } from '@/infra/repositories/stream-info/marketplace/marketplaceStreamInfoRepository.interface';

export interface StreamFromMarketplaceApi {
  id: number;
  name: string;
  source_url: string;
  source_thumb: string;
  animated_thumb: string | null;
  started_at: number;
  ended_at: number | null;
  product_count: number;
  peak_viewers: number;
  is_live: boolean;
  label: string | null;
  is_wide_cell: boolean;
  influencerSlug: string;
  events: {
    comments: {
      comment: string;
      shown_at: number;
      type: string;
      user_image_url: string;
      user_name: string;
    }[];
    has_more: boolean;
    reactions: {
      count: number;
      duration: number;
      shown_at: number;
    }[];
  };
  sharing: {
    display: string;
    shareTitle: string;
    shareMessage: string;
    shareUrl: string;
  };
  stream_info: StreamInfoFromMarketplaceApi | null;
  products: StreamFromMarketplaceApiProduct[];
}

export interface StreamFromMarketplaceApiProduct {
  product_id: number;
  product_name: string;
  identifier: string;
  thumbnail: string;
  price: number;
  price_label: string;
  quantity: number;
  badge_label: string | null;
  overlay_text: [string, string];
  shown_at: number;
  hidden_at: number | null;
  is_favorite: boolean;
  inventory: StreamFromMarketplaceApiProductInventoryItem[];
  extra_media: ExtraMediaFromMarketplaceApi[];
  description: string;
  store_description: string;
}

export interface StreamFromMarketplaceApiProductInventoryItem {
  id: number;
  product_id: number;
  quantity: number;
  color: string | null;
  size: string | null;
  weight: string;
  price: number;
  sale_price: number;
  wholesale_price: number;
  cost: number;
  location: string | null;
  shopify_inventory_id: string | null;
  shopify_inventory_item_id: string | null;
  shopify_barcode: string | null;
  shopify_webhook_status: string | null;
  sku: string | null;
  shopify_fetch: string | null;
  length: number;
  width: number;
  height: number;
  min_wholesale_qty: number | null;
  bigcommerce_inventory_id: string | null;
  position: number | null;
  note: string | null;
  lightspeed_item_id: string | null;
  lightspeed_item_shop_id: string | null;
  external_quantity_diff: number;
  quantity_updated_at: string;
  archived_at: string | null;
  details: string | null;
  received: boolean;
  last_received_at: string | null;
  variant_name: string;
}

export type ExtraMediaFromMarketplaceApi =
  | VideoFromMarketplaceApi
  | {
      media_url: string;
    };

export interface VideoFromMarketplaceApi {
  media_url: string;
  media_type: string;
  thumbnail_url: string;
}

export function isVideoFromMarketplaceApi(
  thing: ExtraMediaFromMarketplaceApi
): thing is VideoFromMarketplaceApi {
  // I think this is right.
  return !!(thing as VideoFromMarketplaceApi).thumbnail_url;
}
