import React, { FC } from 'react';
import { useChannel } from '@harelpls/use-pusher';
import { Channel, PresenceChannel } from 'pusher-js';
import { selectStreamInfo } from '@/view/state/stream-info/streamInfoSlice';
import { useAppSelector } from '@/view/state/store';
import { makeContext } from '@/view/context/helpers/makeContext';

// There is a bug in the useChannel hook that will disconnect from the pusher
// channel any time the component or hook using it unmounts. This was very
// hard to track down. To keep it from happening, we are advised to hoist
// the useChannel up into it's own context that will not be unmounted.
// https://github.com/mayteio/use-pusher/issues/43

type LiveEventsChannelContextType = (Channel & PresenceChannel) | undefined;

const {
  MadeProvider: LiveEventsChannelContextProvider,
  useMadeContext: useLiveEventsChannel,
} = makeContext<LiveEventsChannelContextType>();

const LiveEventsChannelProvider: FC = ({ children }) => {
  const { streamInfo } = useAppSelector(selectStreamInfo);
  const eventChannel = useChannel(`private-${streamInfo?.pusherChannel}`);

  return (
    <LiveEventsChannelContextProvider value={eventChannel}>
      {children}
    </LiveEventsChannelContextProvider>
  );
};

export { LiveEventsChannelProvider, useLiveEventsChannel };
