import { useAppDispatch, useAppSelector } from '@/view/state/store';
import {
  selectStream,
  setIsMuted as setIsMutedAction,
  setIsPlaying as setIsPlayingAction,
  setIsFullScreen as setIsFullScreenAction,
} from '@/view/state/stream/streamSlice';

export function useStream() {
  const dispatch = useAppDispatch();
  const { isPlaying, isMuted, isFullScreen } = useAppSelector(selectStream);

  function setIsPlaying(value: boolean) {
    dispatch(setIsPlayingAction(value));
  }

  function setIsMuted(value: boolean) {
    dispatch(setIsMutedAction(value));
  }

  function setIsFullScreen(value: boolean) {
    dispatch(setIsFullScreenAction(value));
  }

  return {
    isPlaying,
    setIsPlaying,
    isMuted,
    setIsMuted,
    isFullScreen,
    setIsFullScreen,
  };
}
