import React from 'react';
import { ProductDetailContainer } from '../Product/ProductDetailContainer';
import { LiveProductThumbnail } from './LiveProductThumbnail';
import closeIcon from '@/view/ui/assets/close-icon.svg';
import { Product } from '@/domain/entities/product';

interface LiveShopAllProps {
  userId: number;
  selectedProduct?: Product;
  setSelectedProduct: (product?: Product) => void;
  allProducts: Product[];
  closeShopAll: () => void;
}

const LiveShopAll: React.FC<LiveShopAllProps> = ({
  userId,
  selectedProduct,
  setSelectedProduct,
  allProducts,
  closeShopAll,
}) => {
  return (
    <div className="lotw-tw-p-2 lotw-tw-h-full">
      {selectedProduct ? (
        <ProductDetailContainer
          userId={userId}
          productId={selectedProduct.id}
          onBack={() => setSelectedProduct(undefined)}
        />
      ) : (
        <div className="lotw-tw-h-full">
          <button
            className="lotw-tw-absolute lotw-tw-top-1 lotw-tw-right-1 lotw-tw-z-10"
            onClick={closeShopAll}
          >
            <img src={closeIcon} alt="Close" />
          </button>

          <h2 className="shop-all__heading">In This Video</h2>
          <div className="shop-all__add-products-container">
            {allProducts.map((product) => (
              <div
                key={product.uniqueId}
                className="shop-all__product-thumbnail__wrapper"
              >
                <LiveProductThumbnail
                  product={product}
                  onClick={setSelectedProduct}
                  identifier={product.identifier}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export { LiveShopAll };
