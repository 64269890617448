const androidUserAgentRegex = /Android/i;

const iOSUserAgentRegex = /iPhone|iPad|iPod/i;

const isAndroid = (): boolean =>
  androidUserAgentRegex.test(navigator.userAgent);

const isIOS = (): boolean => iOSUserAgentRegex.test(navigator.userAgent);

export { isAndroid, isIOS };
