import { InventoryItem } from '@/domain/entities/product';

interface BuildCtaTextParams {
  selectedVariant:
    | {
        position: number | null | undefined;
        price: number | undefined;
        id: number | undefined;
        quantity: number | undefined;
        color: string | undefined;
        size: string | undefined;
      }
    | InventoryItem
    | undefined;
  isAddingToCart: boolean | undefined;
  areVariantsDisabled?: boolean;
  productCtaText?: string;
}

export function buildCtaText({
  areVariantsDisabled,
  selectedVariant,
  isAddingToCart,
  productCtaText,
}: BuildCtaTextParams) {
  const buttonType = selectedVariant?.quantity ? 'Cart' : 'Waitlist';
  const cta = productCtaText || `Add to ${buttonType}`;
  const addingCta = productCtaText ? 'Adding...' : `Adding to ${buttonType}...`;

  if (areVariantsDisabled) {
    return isAddingToCart ? addingCta : cta;
  } else {
    return !selectedVariant
      ? 'Please make a selection'
      : isAddingToCart
      ? addingCta
      : cta;
  }
}

interface IsCtaDisabled {
  areVariantsDisabled: boolean | undefined;
  isReadOnly: boolean | undefined;
  selectedVariant: InventoryItem | undefined;
  isAddingToCart: boolean | undefined;
}

export function getIsCtaDisabled({
  areVariantsDisabled,
  isReadOnly,
  selectedVariant,
  isAddingToCart,
}: IsCtaDisabled) {
  return areVariantsDisabled
    ? false
    : isReadOnly || !selectedVariant || isAddingToCart;
}
