import { makePusherAuthorizer } from '@/view/hooks/live-events/helpers/makePusherAuthorizer';

const makePusherConfig = ({
  authUrl,
  onError,
  pusherKey,
  pusherCluster,
}: {
  authUrl: string;
  onError: () => void;
  pusherKey: string;
  pusherCluster: string;
}) => {
  return {
    clientKey: pusherKey,
    cluster: pusherCluster,
    authorizer: makePusherAuthorizer({
      authUrl,
      onError,
    }),
  };
};

export { makePusherConfig };
