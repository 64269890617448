import React, { FC, useEffect } from 'react';
import { Provider as StoreProvider } from 'react-redux';

import { initContainer } from '@/container';
import { store } from '@/view/state/store';
import { setLotwConfig } from '@/view/state/lotwConfig/lotwConfigSlice';
import { setCustomer } from '@/view/state/customer/customerSlice';
import { makeContext } from '@/view/context/helpers/makeContext';
import { initTheme } from '@/utils/theme';
import { setScrubToTime } from '../state/video/videoSlice';

interface Customer {
  avatarUrl: string;
  email: string;
  id: number;
  isGuest: boolean;
  name: string;
}

interface LotwConfigCustomization {
  hideSelectedItem: boolean;
  useRoundedCorners: boolean;
}

interface LotwConfig {
  baseUrl: string;
  shopId: string;
  soldKeyword?: string;
  replayTimestamp?: number;
  primaryColors?: { bg: string; text: string };
  dataProvider: DataProvider;
  showSoldCommentsDuringLives?: boolean;
  paypalConfig?: PaypalConfig;
  usesSezzlePayments?: boolean;
  isReadOnly: boolean;
  hideAvailableStockCount: boolean;
  areVariantsDisabled?: boolean;
  productCtaText?: string;
  hideSelectedItem?: LotwConfigCustomization['hideSelectedItem'];
  useRoundedCorners?: LotwConfigCustomization['useRoundedCorners'];
}

type DataProvider = 'webstore' | 'marketplace';

interface PaypalConfig {
  clientId: string;
  merchantId: string;
}

export interface DefaultAddToCartDataStructure {
  type: 'default';
  id: number;
  externalId: string;
  isGiftCard: boolean;
  isReplay: boolean;
  priceCents: number;
  productId: number;
  productName: string;
  productPath: string;
  quantityAvailable: number;
  quantityDesired: number;
  streamId: number;
}

export interface NoVariantAddToCartDataStructure {
  type: 'noVariant';
  externalId: string;
  isReplay: boolean;
  productId: number;
  productName: string;
  productPath: string;
  streamId: number;
}

type AddToCartDataStructure =
  | DefaultAddToCartDataStructure
  | NoVariantAddToCartDataStructure;

interface LotwContext {
  streamId?: number;
  onAddToCart?: (d: AddToCartDataStructure) => void;
  isAddingToCart?: boolean;
  onLiveSaleEnded?: () => void;
  onConnectToStream?: (params: { streamId: number; isLive: boolean }) => void;
  onUserBlocked?: () => void;
  onLogin?: () => void;
  customization: LotwConfigCustomization;
}

const { MadeProvider: LotwContextProvider, useMadeContext: useLotw } =
  makeContext<LotwContext>();

const LotwProvider: FC<{
  streamId?: number;
  config: LotwConfig;
  customer: Customer;
  onLiveSaleEnded?: () => void;
  onAddToCart?: (params: AddToCartDataStructure) => Promise<void>;
  isAddingToCart?: boolean;
  onConnectToStream?: (params: { streamId: number; isLive: boolean }) => void;
  onLogin?: () => void;
  onUserBlocked?: () => void;
}> = ({
  children,
  streamId,
  config,
  customer,
  onAddToCart,
  isAddingToCart,
  onLiveSaleEnded,
  onConnectToStream,
  onUserBlocked,
  onLogin,
}) => {
  // DI container must init here because we need configuration from the consuming app
  initContainer({
    baseUrl: config.baseUrl,
    dataProvider: config.dataProvider,
    shopId: config.shopId,
    streamId,
  });

  const lotwCustomization: LotwConfigCustomization = {
    hideSelectedItem: config.hideSelectedItem ?? false,
    useRoundedCorners: config.useRoundedCorners ?? true,
  };

  initTheme(config.primaryColors, {
    useRoundedCorners: lotwCustomization.useRoundedCorners,
  });

  // Completely reset Redux store when streamId changes
  useEffect(() => {
    store.dispatch({ type: 'reset' });
  }, [streamId]);

  useEffect(() => {
    // Make config from consuming app available in the store
    store.dispatch(setLotwConfig(config));
    store.dispatch(setCustomer(customer));

    if (config?.replayTimestamp) {
      store.dispatch(setScrubToTime(config.replayTimestamp));
    }
  }, [config, customer]);

  return (
    <LotwContextProvider
      value={{
        streamId,
        onAddToCart,
        isAddingToCart,
        onLiveSaleEnded,
        onConnectToStream,
        onUserBlocked,
        onLogin,
        customization: lotwCustomization,
      }}
    >
      <StoreProvider store={store}>{children}</StoreProvider>
    </LotwContextProvider>
  );
};

export {
  AddToCartDataStructure,
  Customer,
  DataProvider,
  LotwConfig,
  LotwProvider,
  PaypalConfig,
  useLotw,
};
