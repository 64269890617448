const usePrice = (price: number, showCurrency = true) => {
  const money = price / 100;

  const config = Object.assign(
    {
      currency: 'USD',
      minimumFractionDigits: 2,
    },
    showCurrency
      ? {
          style: 'currency',
        }
      : {}
  );

  return Intl.NumberFormat(undefined, config).format(
    parseFloat((money ? money : 0).toString())
  );
};

export default usePrice;
