import { MutableRefObject, useEffect, useState } from 'react';

interface Dimensions {
  width: number;
  height: number;
}

export default function useElementSize(
  ref: MutableRefObject<HTMLElement | null>
) {
  const [windowSize, setWindowSize] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const element = ref.current;

    function handleResize() {
      if (!element) return;

      setWindowSize({
        width: element.offsetWidth,
        height: element.offsetHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  return windowSize;
}
