import React, { useEffect, useMemo, useState } from 'react';
import { VariantGrid } from './VariantGrid';
import { InventoryItem, Product } from '@/domain/entities/product';
import findInventoryItem from '@/view/ui/Product/helpers/findInventoryItem';
import createOptions from '@/view/ui/Product/helpers/createOptions';

interface OptionSelectorProps {
  hideAvailableStockCount: boolean;
  product: Product;
  onChoose: (x?: InventoryItem) => void;
}

const VariantSelector: React.FC<OptionSelectorProps> = ({
  hideAvailableStockCount,
  product,
  onChoose,
}) => {
  const [selectedSize, setSelectedSize] = useState<string>('');
  const [selectedColor, setSelectedColor] = useState<string>('');

  useEffect(() => {
    setSelectedSize('');
    setSelectedColor('');
  }, [product, setSelectedSize, setSelectedColor]);

  useEffect(() => {
    const inventoryItem = findInventoryItem({
      inventoryItems: product.inventory,
      selectedSize: selectedSize,
      selectedColor: selectedColor,
    });

    const doesInventoryItemMatchSelections =
      inventoryItem?.size === selectedSize &&
      inventoryItem?.color === selectedColor;

    onChoose(doesInventoryItemMatchSelections ? inventoryItem : undefined);
  }, [product.inventory, onChoose, selectedColor, selectedSize]);

  const options = useMemo(() => {
    return createOptions({
      inventoryItems: product.inventory,
      selectedSize: selectedSize,
      selectedColor: selectedColor,
    });
  }, [product, selectedSize, selectedColor]);

  const handleOptionClick =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (optionTitle: string) => {
      setter((prevTitle) => (prevTitle === optionTitle ? '' : optionTitle));
    };

  const sizesArray = Object.entries(options.sizes);
  const colorsArray = Object.entries(options.colors);

  return (
    <div>
      {/* Could be zero */}
      {options.singleOption !== undefined && (
        <div className="lotw-tw-text-lg lotw-tw-text-black">
          {hideAvailableStockCount
            ? product?.inventory[0]?.quantity
              ? 'In Stock'
              : null
            : `${options.singleOption} left`}
        </div>
      )}

      {!!sizesArray.length && (
        <>
          <h4 className="lotw-tw-mb-2 lotw-tw-text-xs lotw-tw-text-gray-500">
            Sizes
          </h4>

          <VariantGrid
            hideAvailableStockCount={hideAvailableStockCount}
            optionsArray={sizesArray}
            selectedOption={selectedSize}
            onOptionClick={handleOptionClick(setSelectedSize)}
          />
        </>
      )}

      {!!colorsArray.length && (
        <>
          <h4 className="lotw-tw-mt-4 lotw-tw-mb-2 lotw-tw-text-xs lotw-tw-text-gray-500">
            Color
          </h4>

          <VariantGrid
            hideAvailableStockCount={hideAvailableStockCount}
            optionsArray={colorsArray}
            selectedOption={selectedColor}
            onOptionClick={handleOptionClick(setSelectedColor)}
          />
        </>
      )}
    </div>
  );
};

export { VariantSelector };
