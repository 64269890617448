import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
import React from 'react';
import { ChatItemSkeletonLoader } from '@/view/ui/components/molecules/SkeletonLoaders/ChatItemSkeletonLoader';

export const ChatContainerSkeletonLoader: React.FC<{ width: number }> = ({
  width,
}) => (
  <div
    className={cn(
      'live-component__chat-container',
      width > 1200 && 'lotw-tw-block lotw-tw-relative lotw-tw-h-full',
      width >= 400 && 'card--tablet',
      width >= 768 && 'card--desktop'
    )}
  >
    <Skeleton
      className="lotw-tw-h-4 lotw-tw-w-1/3"
      containerClassName="lotw-tw-block lotw-tw-m-4"
    />
    <div className="lotw-tw-bottom-0 lotw-tw-absolute lotw-tw-w-full">
      <ChatItemSkeletonLoader />
      <ChatItemSkeletonLoader />
      <ChatItemSkeletonLoader />
      <ChatItemSkeletonLoader />
      <ChatItemSkeletonLoader />
      <ChatItemSkeletonLoader />
    </div>
  </div>
);
