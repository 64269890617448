import React, { FC, useEffect, useState } from 'react';
import { makePusherConfig } from '@/view/hooks/live-events/helpers/makePusherConfig';
import { PusherProvider, PusherProviderProps } from '@harelpls/use-pusher';
import { LiveEventsChannelProvider } from '@/view/context/live-events/LiveEventsChannelContext';
import { useAppSelector } from '@/view/state/store';
import { selectStreamInfo } from '@/view/state/stream-info/streamInfoSlice';
import { InitLiveEvents } from '@/view/context/live-events/InitLiveEvents';
import { useLotw } from '@/view/context/LotwProvider';

export const LiveEventsProvider: FC = ({ children }) => {
  const [hasPusherError, setHasPusherError] = useState(false);

  const { onLiveSaleEnded } = useLotw();
  const { streamInfo, hasLiveSaleEnded } = useAppSelector(selectStreamInfo);

  useEffect(() => {
    if (!hasLiveSaleEnded) return;

    onLiveSaleEnded?.();
  }, [hasLiveSaleEnded, onLiveSaleEnded]);

  if (!streamInfo) return null;

  const { authUrl, pusherCluster, pusherKey } = streamInfo;

  const pusherConfig: PusherProviderProps = makePusherConfig({
    authUrl,
    onError: () => setHasPusherError(true),
    pusherKey,
    pusherCluster,
  });

  if (hasPusherError) {
    return (
      <div className="m-4" style={{ minHeight: 300 }}>
        <p>
          Could not connect to live events.
          <br />
          <button
            onClick={() => window.location.reload()}
            className="underline mr-1"
          >
            Refresh
          </button>
          the browser to try again or contact support.
        </p>
      </div>
    );
  }

  return (
    <PusherProvider {...pusherConfig}>
      <LiveEventsChannelProvider>
        <InitLiveEvents>{children}</InitLiveEvents>
      </LiveEventsChannelProvider>
    </PusherProvider>
  );
};
