import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ImageWithFallback } from '../atoms/ImageWithFallback';
import imagePlaceholder from '@/view/ui/assets/imagePlaceholder.png';
import { FaPlay } from 'react-icons/fa';
import SwiperClass from 'swiper/types/swiper-class';
import { LightboxMediaGallery } from './LightboxMediaGallery';

interface Media {
  id: string;
  videoSrc?: string;
  src: string;
  alt: string;
}

interface MediaGalleryProps {
  media: Media[];
}

const MediaGallery: React.FC<MediaGalleryProps> = ({ media }) => {
  const [openedMediaIndex, setOpenedMediaIndex] = useState<
    number | undefined
  >();

  const handleMediaClick = (swiper: SwiperClass) => {
    setOpenedMediaIndex(swiper.clickedIndex);
  };

  return (
    <>
      {openedMediaIndex !== undefined && (
        <LightboxMediaGallery
          selectedIndex={openedMediaIndex}
          media={media}
          onClose={() => setOpenedMediaIndex(undefined)}
        />
      )}

      <Swiper
        spaceBetween={16}
        slidesPerView="auto"
        onClick={handleMediaClick}
        style={{ width: '100%' }}
      >
        {media.map((mediaItem) => {
          return (
            <SwiperSlide key={mediaItem.id} style={{ width: 150, height: 150 }}>
              <button>
                {mediaItem.videoSrc && (
                  <div className="lotw-tw-absolute lotw-tw-inset-0 lotw-tw-flex lotw-tw-justify-center lotw-tw-items-center lotw-tw-bg-gray-500 lotw-tw-bg-opacity-75 lotw-tw-transition-opacity lotw-tw-rounded-md">
                    <FaPlay size="2rem" color="white" />
                  </div>
                )}

                <ImageWithFallback
                  src={mediaItem.src}
                  fallbackSrc={imagePlaceholder}
                  alt={mediaItem.alt}
                  className="lotw-tw-rounded-md lotw-tw-object-cover"
                  style={{ width: '100%', height: '100%' }}
                />
              </button>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export { MediaGallery };
