export function initTheme(
  primaryColors?: { bg: string; text: string },
  customization?: { useRoundedCorners: boolean }
) {
  if (!primaryColors) return;

  const { bg, text } = primaryColors;

  setCssVar('--color-bg-primary', bg);
  setCssVar('--color-text-primary', text);
  if (customization && !customization.useRoundedCorners) {
    setCssVar('--lotw-card-border-radius', '0px');
  }
}

// PRIVATE

function setCssVar(name: string, value: string) {
  return document.documentElement.style.setProperty(`${name}`, `${value}`);
}
