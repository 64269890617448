import { HttpService } from '@/infra/services/http/httpService';
import {
  StreamInfo,
  StreamInfoRepository,
} from '@/domain/entities/stream-info';
import {
  ExtraMediaFromWebstoreApi,
  isVideoFromWebstoreApi,
  StreamInfoFromWebstoreApi,
  StreamInfoFromWebstoreApiProductInventoryItem,
  VideoFromWebstoreApi,
} from '@/infra/repositories/stream-info/webstore/webstoreStreamInfoRepository.interface';
import { v4 } from 'uuid';
import { ExtraMedia, Product, Video } from '@/domain/entities/product';
import {
  buildColorsFromInventory,
  buildSizesFromInventory,
} from '@/infra/repositories/helpers';

const makeWebstoreStreamInfoRepository = ({
  httpService,
}: {
  httpService: HttpService;
}): StreamInfoRepository => {
  const get = async () => {
    const streamInfoFromWebstoreApi =
      await httpService.get<StreamInfoFromWebstoreApi>({
        url: '/live/stream-info',
      });

    return makeStreamInfoFromStreamInfoFromWebstoreApi(
      streamInfoFromWebstoreApi
    );
  };

  return Object.freeze({
    get,
  });
};

export { makeWebstoreStreamInfoRepository };

// The repository should convert API responses into domain entities
// We should not use the API entities directly
export function makeStreamInfoFromStreamInfoFromWebstoreApi(
  streamInfoFromWebstoreApi: StreamInfoFromWebstoreApi
): StreamInfo {
  return {
    id: streamInfoFromWebstoreApi.live_sale_id,
    authUrl: streamInfoFromWebstoreApi.authURL,
    isStreamingNow: streamInfoFromWebstoreApi.is_streaming_now,
    isLotwStreamingEnabled: streamInfoFromWebstoreApi.isLotwStreamingEnabled,
    pusherChannel: streamInfoFromWebstoreApi.pusherChannel,
    pusherKey: streamInfoFromWebstoreApi.pusherKey,
    pusherCluster: streamInfoFromWebstoreApi.pusherCluster,
    secondsLive: streamInfoFromWebstoreApi.secondsLive,
    streamUrl: streamInfoFromWebstoreApi.stream_url,
    products: createProductsFromStreamInfo(streamInfoFromWebstoreApi),
  };
}

export const createProductsFromStreamInfo = (
  streamInfoFromWebstoreApi: StreamInfoFromWebstoreApi
): Product[] => {
  return streamInfoFromWebstoreApi.currentProduct.map(
    (productFromWebstoreApi) => {
      return {
        soldText: productFromWebstoreApi.sold_text,
        formattedSizes: buildSizesFromInventory(
          productFromWebstoreApi.inventory
        ),
        formattedColors: buildColorsFromInventory(
          productFromWebstoreApi.inventory
        ),
        type: productFromWebstoreApi.product_type,
        comments: productFromWebstoreApi.comments,
        description: productFromWebstoreApi.description,
        storeDescription: productFromWebstoreApi.store_description,
        id: productFromWebstoreApi.product_id,
        identifier: productFromWebstoreApi.identifier,
        inventory: makeInventoryFromInventoryFromWebstoreApi(
          productFromWebstoreApi.inventory
        ),
        name: productFromWebstoreApi.product_name,
        priceLabel: productFromWebstoreApi.price_label,
        quantity: productFromWebstoreApi.quantity,
        style: productFromWebstoreApi.style,
        thumbnail: productFromWebstoreApi.thumbnail,
        uniqueId: v4(),
        extraMedia: makeExtraMediaFromStreamInfoFromWebstoreApiProduct(
          productFromWebstoreApi.extra_media
        ),
        videos: productFromWebstoreApi.videos.map(
          makeVideoFromVideoFromWebstoreApi
        ),
        path: productFromWebstoreApi.product_path,
        externalId: productFromWebstoreApi.external_id,
      };
    }
  );
};

export const makeInventoryFromInventoryFromWebstoreApi = (
  inventoryFromWebstoreApi: StreamInfoFromWebstoreApiProductInventoryItem[]
) => {
  return inventoryFromWebstoreApi.map((i) => {
    return { ...i, id: i.inventory_id };
  });
};

export const makeExtraMediaFromStreamInfoFromWebstoreApiProduct = (
  extraMediaFromWebstoreApi: ExtraMediaFromWebstoreApi[]
): ExtraMedia[] => {
  return extraMediaFromWebstoreApi.map((em) => {
    if (isVideoFromWebstoreApi(em)) {
      return makeVideoFromVideoFromWebstoreApi(em);
    } else {
      return {
        mediaUrl: em.media_url,
      };
    }
  });
};

export const makeVideoFromVideoFromWebstoreApi = (
  videoFromWebstoreApi: VideoFromWebstoreApi
): Video => {
  return {
    mediaUrl: videoFromWebstoreApi.media_url,
    mediaType: videoFromWebstoreApi.media_type,
    thumbnailUrl: videoFromWebstoreApi.thumbnail_url,
  };
};
