import React, { useState } from 'react';
import { VscChromeClose } from 'react-icons/vsc';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Navigation } from 'swiper';
import { ImageWithFallback } from '../atoms/ImageWithFallback';
import imagePlaceholder from '@/view/ui/assets/imagePlaceholder.png';
import { VideoStream } from '@/view/ui/Live/VideoStream';

SwiperCore.use([A11y, Navigation]);

interface Media {
  id: string;
  videoSrc?: string;
  src: string;
  alt: string;
}

interface LightboxMediaGalleryProps {
  selectedIndex: number;
  media: Media[];
  onClose: () => void;
}

const LightboxMediaGallery: React.FC<LightboxMediaGalleryProps> = ({
  selectedIndex,
  media,
  onClose,
}) => {
  const [currentIndex, setCurrentIndex] = useState(selectedIndex);
  return (
    <div
      className={
        'lotw-tw-fixed lotw-tw-inset-0 lotw-tw-bg-black lotw-tw-bg-opacity-70 lotw-tw-transition-opacity lotw-tw-flex lotw-tw-items-center lotw-tw-justify-center lotw-tw-z-30'
      }
    >
      <button
        onClick={onClose}
        className="lotw-tw-absolute lotw-tw-top-3 lotw-tw-right-3 lotw-tw-z-10"
      >
        <VscChromeClose size="2rem" color="white" />
      </button>

      <Swiper
        slidesPerView={1}
        initialSlide={selectedIndex}
        navigation={true}
        autoHeight={true}
        onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
      >
        {media.map((mediaItem, i) => {
          return (
            <SwiperSlide key={mediaItem.id}>
              <div className="lotw-tw-flex lotw-tw-justify-center lotw-tw-items-center">
                {mediaItem.videoSrc ? (
                  <div
                    style={{
                      width: 500,
                      maxWidth: '100vw',
                      height: 900,
                      maxHeight: '100vh',
                    }}
                    className="lotw-tw-rounded-md lotw-tw-overflow-hidden"
                  >
                    {currentIndex === i && (
                      <VideoStream streamUrl={mediaItem.videoSrc} />
                    )}
                  </div>
                ) : (
                  <ImageWithFallback
                    src={mediaItem.src}
                    fallbackSrc={imagePlaceholder}
                    alt={mediaItem.alt}
                    className="lotw-tw-rounded-md lotw-tw-object-cover"
                  />
                )}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export { LightboxMediaGallery };
