import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/view/state/store';
import { selectLotwConfig } from '@/view/state/lotwConfig/lotwConfigSlice';
import { setScrubToTime } from '@/view/state/video/videoSlice';
import { LiveReplay } from '@/view/ui/Live/LiveReplay';
import { loadReplayData } from '@/view/state/replay/replaySlice';
import { Replay } from '@/domain/entities/replay';
import { setIsLive } from '@/view/state/misc/miscSlice';

interface LiveReplayContainerProps {
  replay?: Replay;
}

const LiveReplayContainer: React.FC<LiveReplayContainerProps> = ({
  replay,
}) => {
  const dispatch = useAppDispatch();
  const { replayTimestamp } = useAppSelector(selectLotwConfig);

  useEffect(() => {
    if (!replay) return;
    dispatch(loadReplayData(replay.id));
    // Some decisions in Redux are made based on whether we are
    // watching a live stream or a replay
    dispatch(setIsLive(false));
  }, [replay, dispatch]);

  useEffect(() => {
    if (replayTimestamp !== undefined) {
      dispatch(setScrubToTime(replayTimestamp));
    }
  }, [replayTimestamp, dispatch]);

  if (!replay) return null;

  return <LiveReplay replay={replay} />;
};

export { LiveReplayContainer };
