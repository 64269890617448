import React from 'react';
import imagePlaceholder from '@/view/ui/assets/imagePlaceholder.png';
import { Product } from '@/domain/entities/product';
import { ImageWithFallback } from '@/view/ui/components/atoms/ImageWithFallback';

interface LiveProductThumbnailProps {
  product: Product;
  identifier?: string;
  onClick: (product: Product) => void;
}

const LiveProductThumbnail: React.FC<LiveProductThumbnailProps> = ({
  product,
  identifier,
  onClick,
}) => {
  return (
    <>
      <div
        onClick={() => onClick(product)}
        className="live-product-thumbnail__image-wrapper lotw-tw-relative"
      >
        <ImageWithFallback
          src={product.thumbnail}
          fallbackSrc={imagePlaceholder}
          alt={product.name}
          width={168}
        />

        {identifier !== undefined && (
          <div className="product-info__identifier-box">{identifier}</div>
        )}
      </div>

      <div className="live-product-thumbnail__price">{product.priceLabel}</div>
      <div className="live-product-thumbnail__name">{product.name}</div>
    </>
  );
};

export { LiveProductThumbnail };
