import React from 'react';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { FaShoppingBag } from 'react-icons/fa';
import imagePlaceholder from '@/view/ui/assets/imagePlaceholder.png';
import { Product } from '@/domain/entities/product';
import { ImageWithFallback } from '@/view/ui/components/atoms/ImageWithFallback';
import Button from '@/view/ui/components/atoms/Button';
import { dispatchLOTWEvent } from '@/shared-kernel/events';

export interface ProductInfoProps {
  product: Product;
  onViewProduct: (product: Product) => void;
  isPrimary?: boolean;
}

const LiveProductInfo: React.FC<ProductInfoProps> = ({
  product,
  onViewProduct,
  isPrimary = false,
}) => {
  const {
    description,
    thumbnail,
    name,
    identifier,
    priceLabel,
    formattedColors,
    formattedSizes,
  } = product;

  const handleViewProduct = () => {
    onViewProduct(product);

    dispatchLOTWEvent('ViewProduct', product);
  };

  const isInCart = false; // TODO: isProductInCart(product, cart);

  return (
    <div className="lotw-tw-p-2">
      <div className="lotw-tw-flex lotw-tw-w-full">
        <div className="product-info__thumbnail-wrapper flex-0">
          <ImageWithFallback
            src={thumbnail}
            fallbackSrc={imagePlaceholder}
            alt={description}
            width={132}
            height={132}
          />
          <div className="product-info__identifier-box">{identifier}</div>
        </div>

        <div className="lotw-tw-ml-4 lotw-tw-flex-1">
          <h3 className="product-info__name lotw-tw-mt-0 lotw-tw-mb-2 lotw-tw-leading-tight">
            {name}
          </h3>

          <div className="lotw-tw-text-sm lotw-tw-text-gray-500">
            {formattedSizes && (
              <div className="lotw-tw-text-sm">
                <div className="lotw-tw-flex lotw-tw-mt-1">
                  <div className="lotw-tw-flex-1 lotw-tw-w-full">
                    <ReactMarkdown remarkPlugins={[gfm]}>
                      {formattedSizes}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            )}

            {formattedColors && (
              <div className="lotw-tw-flex lotw-tw-mt-1">
                <div className="lotw-tw-flex-1 lotw-tw-w-full">
                  <ReactMarkdown remarkPlugins={[gfm]}>
                    {formattedColors}
                  </ReactMarkdown>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="lotw-tw-flex lotw-tw-mt-4 lotw-tw-items-center">
        <div className="product-info__price-box">{priceLabel}</div>

        {isInCart && (
          <div
            className="lotw-tw-ml-auto lotw-tw-flex lotw-tw-text-small"
            style={{ color: '#3C8DBC' }}
          >
            <FaShoppingBag /> <span className="lotw-tw-ml-1">In Cart</span>
          </div>
        )}

        <Button
          className={cn({
            'lotw-tw-ml-auto': !isInCart,
            'lotw-tw-ml-2': isInCart,
          })}
          primary={isPrimary}
          onClick={handleViewProduct}
        >
          View Product
        </Button>
      </div>
    </div>
  );
};

export { LiveProductInfo };
