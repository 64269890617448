import React, { FC, useEffect, useState } from 'react';
import { FaClock, FaExpandAlt, FaEye, FaUndoAlt } from 'react-icons/fa';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import dayjs from 'dayjs';
import { TIME_FORMATS } from '@/shared-kernel/constants';
import { useAppSelector } from '@/view/state/store';
import { selectCurrentVideoTime } from '@/view/state/video/videoSlice';
import imagePlaceholder from '@/view/ui/assets/imagePlaceholder.png';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const formatTimeFromSeconds = (seconds: number): string => {
  const beginningOfStream = dayjs().subtract(seconds, 'seconds');
  const duration = dayjs.duration(dayjs().diff(beginningOfStream));

  return duration.asHours() > 1
    ? duration.format(TIME_FORMATS.DURATION_HOURS_MINUTES_SECONDS)
    : duration.format(TIME_FORMATS.DURATION_MINUTES_SECONDS);
};

const Timer: FC<{ startSeconds: number }> = ({ startSeconds }) => {
  const [elapsedTime, setElapsedTime] = useState(startSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime((prevState) => ++prevState);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="lotw-tw-ml-auto lotw-tw-mr-2 overlay-info"
      data-testid={'elapsed-time'}
    >
      <FaClock className="lotw-tw-mr-2" />
      {formatTimeFromSeconds(elapsedTime)}
    </div>
  );
};

export interface OverlayProps {
  viewers: number;
  isLive: boolean;
  soldText?: string;
  sizes?: string;
  colors?: string;
  price?: string;
  productImgSrc?: string;
  onBuyClick: () => void;
  hideProduct?: boolean;
  elapsedTimeWhenMounted: number;
  onFullScreenClick: () => void;
  isMobile: boolean;
}

const Overlay: React.FC<OverlayProps> = ({
  elapsedTimeWhenMounted,
  viewers,
  isLive,
  soldText,
  sizes,
  colors,
  price,
  productImgSrc,
  onBuyClick,
  hideProduct = false,
  onFullScreenClick,
  isMobile,
}) => {
  const currentVideoTime = useAppSelector(selectCurrentVideoTime);

  return (
    <div className="lotw-tw-bg-transparent lotw-tw-absolute lotw-tw-top-0 lotw-tw-right-0 lotw-tw-left-0 lotw-tw-p-2">
      <div
        className={cn(
          'lotw-tw-flex lotw-tw-justify-between lotw-tw-items-center'
        )}
      >
        <div
          className="overlay-info overlay-info__live lotw-tw-uppercase"
          data-testid="isLive"
        >
          {isLive ? (
            <div className={'overlay-info__live--circle'} />
          ) : (
            <FaUndoAlt size="0.75rem" className={'lotw-tw-mr-1'} />
          )}
          {isLive ? 'live' : 'replay'}
        </div>

        {isLive ? (
          <Timer startSeconds={elapsedTimeWhenMounted} />
        ) : (
          <div
            className="lotw-tw-ml-auto lotw-tw-mr-2 overlay-info"
            data-testid={'elapsed-time'}
          >
            <FaClock className="lotw-tw-mr-2" />
            {formatTimeFromSeconds(currentVideoTime)}
          </div>
        )}

        <div className="overlay-info" data-testid={'current-viewers'}>
          <FaEye className="lotw-tw-mr-2" />
          {viewers}
        </div>

        {isMobile && (
          <button
            onClick={onFullScreenClick}
            className="lotw-tw-ml-6"
            name={'full_screen'}
          >
            <FaExpandAlt color="white" size="1.5em" />
          </button>
        )}
      </div>

      {!hideProduct && (
        <button
          className="lotw-tw-mt-4 lotw-tw-flex lotw-tw-justify-between lotw-tw-items-start overlay-product lotw-tw-w-full lotw-tw-text-left"
          onClick={onBuyClick}
          name={'buy_product'}
        >
          <div className="lotw-tw-relative lotw-tw-flex-1 lotw-tw-mr-4 overlay-product__bubble">
            {price && (
              <div className="lotw-tw-flex lotw-tw-justify-between lotw-tw-items-center">
                <div className="overlay-product__sold-text">
                  <ReactMarkdown remarkPlugins={[gfm]}>
                    {soldText || ''}
                  </ReactMarkdown>
                </div>
                <div
                  className="overlay-product__price"
                  data-testid={'product-price'}
                >
                  {price}
                </div>
              </div>
            )}

            {/* This can fallback to just product name if there are no sizes. */}
            {sizes && (
              <div className="lotw-tw-flex lotw-tw-mt-1 overlay-product__info">
                <span className="lotw-tw-flex-1" data-testid={'product-sizes'}>
                  <ReactMarkdown remarkPlugins={[gfm]}>{sizes}</ReactMarkdown>
                </span>
              </div>
            )}

            {colors && (
              <div className="lotw-tw-flex lotw-tw-mt-1 overlay-product__info">
                <span className="lotw-tw-flex-1" data-testid={'product-colors'}>
                  <ReactMarkdown remarkPlugins={[gfm]}>{colors}</ReactMarkdown>
                </span>
              </div>
            )}

            <div className="overlay-product__bubble-point">
              <div />
            </div>
          </div>

          <div className="lotw-tw-relative">
            <div
              className="overlay-product__image"
              style={{
                backgroundImage: `url(${productImgSrc || imagePlaceholder})`,
              }}
            />
            <div className="overlay-product__buy-button">BUY</div>
          </div>
        </button>
      )}
    </div>
  );
};

export { Overlay };
