import React from 'react';
import { ChatReplayContainer } from '@/view/ui/Chat/ChatReplayContainer';
import { ChatLiveContainer } from '@/view/ui/Chat/ChatLiveContainer';

interface ChatContainerProps {
  expirationTime?: string;
  onShopAll: () => void;
  isInOverlay?: boolean;
  isDisabled?: boolean;
  isMobile?: boolean;
  isLive: boolean;
  isReadOnly: boolean;
}

const ChatContainer: React.FC<ChatContainerProps> = (props) => {
  return props.isLive ? (
    <ChatLiveContainer {...props} />
  ) : (
    <ChatReplayContainer {...props} />
  );
};

export { ChatContainer };
