import { ReplayFromWebstoreApi } from '@/infra/repositories/replay/webstore/webstoreReplayRepository.interface';

export interface StreamFromWebstoreApiWithProducts
  extends ReplayFromWebstoreApi {
  source_url: string;
  products: StreamFromWebstoreApiProduct[];
}

export interface StreamFromWebstoreApiProduct {
  product_id: number;
  product_name: string;
  identifier: string;
  thumbnail: string | null;
  extra_media: ExtraMediaFromWebstoreApi[];
  price: number;
  price_label: string;
  quantity: number;
  badge_label: string;
  overlay_text: [string, string];
  shown_at: number;
  hidden_at: number | null;
  is_favorite: boolean;
  inventory: StreamFromWebstoreApiProductInventoryItem[];
  description: string;
  store_description: string;
  product_path: string;
  external_id: string;
}

export interface StreamFromWebstoreApiProductInventoryItem {
  position: number | null;
  price: number;
  id: number;
  quantity: number;
  color: string | null;
  size: string | null;
}

export type ExtraMediaFromWebstoreApi =
  | VideoFromWebstoreApi
  | {
      media_url: string;
    };

export interface VideoFromWebstoreApi {
  media_url: string;
  media_type: string;
  thumbnail_url: string;
}

export function isVideoFromWebstoreApi(
  thing: ExtraMediaFromWebstoreApi
): thing is VideoFromWebstoreApi {
  // I think this is right.
  return !!(thing as VideoFromWebstoreApi).thumbnail_url;
}
