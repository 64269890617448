import { ReplayRepository } from '@/domain/entities/replay';

const makeMarketplaceReplayRepository = (): ReplayRepository => {
  const get = async () => {
    console.info(
      'The Marketplace data provider does not provide a list of replays'
    );
    return [];
  };

  return Object.freeze({
    get,
  });
};

export { makeMarketplaceReplayRepository };
