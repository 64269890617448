import React, { FC, useMemo } from 'react';
import { throttle } from 'lodash';
import { VideoJsPlayerOptions } from 'video.js';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '@/view/state/store';
import {
  selectScrubToTime,
  setCurrentVideoTime,
} from '@/view/state/video/videoSlice';
import VideoPlayer from '@/view/ui/Live/VideoPlayer';

export const VideoStream: FC<{
  streamUrl?: string;
  isFullScreen?: boolean;
}> = ({ streamUrl, isFullScreen }) => {
  const scrubToTime = useAppSelector(selectScrubToTime);
  const dispatch = useAppDispatch();

  const throttledTimeCallbacks = useMemo(
    () =>
      throttle((time) => {
        dispatch(setCurrentVideoTime(time));
      }, 1000),
    [dispatch]
  );

  const videoJsOptions = useMemo(() => {
    return buildVideoJsOptions(streamUrl);
  }, [streamUrl]);

  return (
    // This margin fixes a black line behind around the video that's hard to solve
    // The minHeight is to make the widget height match the video height, even if
    // the video is not yet playing.
    <div style={{ margin: -1, minHeight: 500, height: '100%' }}>
      <VideoPlayer
        // Preserve video element
        key="VideoPlayer-instance"
        videoJsOptions={videoJsOptions}
        onTimeUpdate={(e) =>
          throttledTimeCallbacks(e.currentTarget.currentTime)
        }
        tryToAutoplay={true}
        timestamp={scrubToTime}
        className={cn(isFullScreen && 'vjs-fixed-controls')}
      />
    </div>
  );
};

// PRIVATE

function buildVideoJsOptions(streamUrl?: string): VideoJsPlayerOptions {
  return {
    fluid: true,
    sources: streamUrl
      ? [
          {
            src: streamUrl,
            type: 'application/x-mpegURL',
          },
        ]
      : [],
  };
}
