import { makeWebstoreStreamInfoRepository } from '@/infra/repositories/stream-info/webstore/webstoreStreamInfoRepository';
import { makeGetStreamInfo } from '@/domain/use-cases/stream-info/getStreamInfo';
import { makeHttpService } from '@/infra/services/http/httpService';
import { makeGetStream } from '@/domain/use-cases/stream/getStream';
import { makeWebstoreStreamRepository } from '@/infra/repositories/stream/webstore/webstoreStreamRepository';
import { makeGetReplays } from '@/domain/use-cases/replay/getReplays';
import { makeLoadReplayData } from '@/domain/use-cases/replay/loadReplayData';
import { makeWebstoreReplayRepository } from '@/infra/repositories/replay/webstore/webstoreReplayRepository';
import { makeGetComments } from '@/domain/use-cases/comment/getComments';
import { makeWebstoreLiveEventsRepository } from '@/infra/repositories/live-events/webstore/webstoreLiveEventsRepository';
import { makeGetReactions } from '@/domain/use-cases/reaction/getReactions';
import { addComment } from '@/domain/entities/comment';
import { addReaction } from '@/domain/entities/reaction';
import {
  addProduct,
  getProductById,
  getProductsBeforeTimestamp,
} from '@/domain/entities/product';
import { makeMarketplaceStreamInfoRepository } from '@/infra/repositories/stream-info/marketplace/marketplaceStreamInfoRepository';
import { makeMarketplaceStreamRepository } from '@/infra/repositories/stream/marketplace/marketplaceStreamRepository';
import { makeMarketplaceReplayRepository } from '@/infra/repositories/replay/marketplace/marketplaceReplayRepository';
import { makeMarketplaceLiveEventsRepository } from '@/infra/repositories/live-events/marketplace/marketplaceLiveEventsRepository';

// This component serves as the composition root.
// All dependencies are injected here.

let containerInstance: Container;

interface ContainerParams {
  baseUrl: string;
  dataProvider?: 'webstore' | 'marketplace';
  shopId?: string;
  streamId?: number;
}

const makeContainer = ({
  baseUrl,
  dataProvider = 'webstore',
  shopId,
  streamId,
}: ContainerParams) => {
  switch (dataProvider) {
    case 'webstore':
      return makeWebstoreContainer({ baseUrl });
    case 'marketplace':
      if (!shopId || !streamId) return;

      return makeMarketplaceContainer({ baseUrl, shopId, streamId });
  }
};

type Container = ReturnType<typeof makeContainer>;

const initContainer = (params: ContainerParams) => {
  try {
    containerInstance = makeContainer(params);
  } catch (e) {
    console.error('Could not make container', e);
  }
};

const getContainerInstance = () => {
  if (!containerInstance) {
    throw new Error('Container not initialized');
  }

  return containerInstance;
};

export { Container, initContainer, getContainerInstance };

// PRIVATE

const makeWebstoreContainer = ({ baseUrl }: { baseUrl: string }) => {
  const httpService = makeHttpService({ baseUrl });

  const streamInfoRepo = makeWebstoreStreamInfoRepository({ httpService });
  const streamRepo = makeWebstoreStreamRepository({ httpService });
  const replayRepo = makeWebstoreReplayRepository({ httpService });
  const liveEventsRepo = makeWebstoreLiveEventsRepository({ httpService });

  const getStreamInfo = makeGetStreamInfo({ streamInfoRepo });
  const getStream = makeGetStream({ streamRepo });
  const getReplays = makeGetReplays({ replayRepo });
  const getComments = makeGetComments({
    commentRepo: liveEventsRepo.commentRepo,
  });
  const getReactions = makeGetReactions({
    reactionRepo: liveEventsRepo.reactionRepo,
  });
  const loadReplayData = makeLoadReplayData({
    getComments,
    getReactions,
    getStream,
  });

  return Object.freeze({
    useCases: {
      getStreamInfo,
      getStream,
      getReplays,
      loadReplayData,
      getAllComments: getComments.getAllComments,
      getCommentsBeforeTimestamp: getComments.getCommentsBeforeTimestamp,
      handleComment: getComments.handleComment,
      getAllReactions: getReactions.getAllReactions,
      getReactionsBeforeTimestamp: getReactions.getReactionsBeforeTimestamp,
      addComment,
      addReaction,
      addProduct,
      getProductById,
      getProductsBeforeTimestamp,
    },
  });
};

const makeMarketplaceContainer = ({
  baseUrl,
  shopId,
  streamId,
}: {
  baseUrl: string;
  shopId: string;
  streamId: number;
}) => {
  const httpService = makeHttpService({ baseUrl });

  const streamRepo = makeMarketplaceStreamRepository({
    httpService,
    shopId,
  });

  const streamInfoRepo = makeMarketplaceStreamInfoRepository({
    httpService,
    shopId,
    streamId,
  });

  // This always returns an empty array.
  // Not needed for Marketplace
  const replayRepo = makeMarketplaceReplayRepository();
  const liveEventsRepo = makeMarketplaceLiveEventsRepository({
    httpService,
    shopId,
  });

  const getStreamInfo = makeGetStreamInfo({ streamInfoRepo });
  const getStream = makeGetStream({ streamRepo });
  const getReplays = makeGetReplays({ replayRepo });
  const getComments = makeGetComments({
    commentRepo: liveEventsRepo.commentRepo,
  });
  const getReactions = makeGetReactions({
    reactionRepo: liveEventsRepo.reactionRepo,
  });
  const loadReplayData = makeLoadReplayData({
    getComments,
    getReactions,
    getStream,
  });

  return Object.freeze({
    useCases: {
      getStreamInfo,
      getStream,
      getReplays,
      loadReplayData,
      getAllComments: getComments.getAllComments,
      getCommentsBeforeTimestamp: getComments.getCommentsBeforeTimestamp,
      handleComment: getComments.handleComment,
      getAllReactions: getReactions.getAllReactions,
      getReactionsBeforeTimestamp: getReactions.getReactionsBeforeTimestamp,
      addComment,
      addReaction,
      addProduct,
      getProductById,
      getProductsBeforeTimestamp,
    },
  });
};
