type ProductId = number;

interface Product {
  id: ProductId;
  type: string;
  name: string;
  description: string;
  storeDescription: string;
  quantity: number;
  style: string;
  uniqueId: string;
  soldText: string;
  formattedColors: string;
  formattedSizes: string;
  shownAt?: number;
  priceLabel: string;
  thumbnail: string;
  identifier: string;
  inventory: InventoryItem[];
  comments: unknown[];
  extraMedia: ExtraMedia[];
  videos: Video[];
  path?: string;
  externalId?: string;
}

export interface AppendedProduct {
  id: ProductId;
  inventory: InventoryItem[];
  extraMedia: ExtraMedia[];
}

export interface InventoryItem {
  position: number | null;
  price: number;
  id: number;
  quantity: number;
  color: string;
  size: string;
}

export type ExtraMedia =
  | Video
  | {
      mediaUrl: string;
    };

export interface Video {
  mediaUrl: string;
  mediaType: string;
  thumbnailUrl: string;
}

export function isVideo(thing: ExtraMedia): thing is Video {
  // I think this is right.
  return !!(thing as Video).thumbnailUrl;
}

const addProduct = (allProducts: Product[], newProduct: Product) => {
  return [newProduct, ...allProducts];
};

const mergeProduct = (
  product: Product,
  appendedProduct: AppendedProduct
): Product => {
  return {
    ...product,
    ...{
      extraMedia: [...product.extraMedia, ...appendedProduct.extraMedia],
      inventory: [...product.inventory, ...appendedProduct.inventory],
    },
  };
};

const updateProductInProducts = (
  products: Product[],
  product: Product
): Product[] => {
  return products.map((currentProduct) => {
    if (currentProduct.id === product.id) {
      return product;
    }

    return currentProduct;
  });
};

const getProductById = (products: Product[], id: number) => {
  return products.find((product) => product.id === id);
};

const getProductsBeforeTimestamp = (products: Product[], timestamp: number) => {
  return products.filter(
    (product) => product.shownAt && product.shownAt <= timestamp
  );
};

export {
  Product,
  ProductId,
  getProductsBeforeTimestamp,
  addProduct,
  mergeProduct,
  updateProductInProducts,
  getProductById,
};
