import { useEvent } from '@harelpls/use-pusher';
import { useLiveEventsChannel } from '@/view/context/live-events/LiveEventsChannelContext';
import config from '@/shared-kernel/config';
import { useAppDispatch, useAppSelector } from '@/view/state/store';
import {
  AppCommentEventPayload,
  AppendCurrentProductEventPayload,
  CurrentProductEventPayload,
  EventType,
  LiveEvent,
  LiveSaleEndedEvent,
  ReactionEvent,
} from '@/view/hooks/live-events/liveEvents.interface';
import { addComment } from '@/view/state/comment/commentSlice';
import {
  addProduct,
  setAppendedProduct,
  setCurrentProductId,
} from '@/view/state/product/productSlice';
import { addReaction } from '@/view/state/reaction/reactionSlice';
import {
  setIsOverlayCleared,
  setLiveViewerCount,
} from '@/view/state/misc/miscSlice';
import {
  selectCustomer,
  trollHasBeenKicked,
} from '@/view/state/customer/customerSlice';
import { Comment } from '@/domain/entities/comment';
import { AppendedProduct, Product } from '@/domain/entities/product';
import { Reaction } from '@/domain/entities/reaction';
import { v4 } from 'uuid';
import { setHasLiveSaleEnded } from '@/view/state/stream-info/streamInfoSlice';
import {
  makeExtraMediaFromStreamInfoFromWebstoreApiProduct,
  makeVideoFromVideoFromWebstoreApi,
  makeInventoryFromInventoryFromWebstoreApi,
} from '@/infra/repositories/stream-info/webstore/webstoreStreamInfoRepository';

export default function useConnectLiveEvents() {
  const dispatch = useAppDispatch();
  const { id: customerId } = useAppSelector(selectCustomer);
  const liveEventsChannel = useLiveEventsChannel();

  const eventName = config.PUSHER_EVENT_NAME;
  const clientEventName = config.PUSHER_CLIENT_EVENT_NAME;
  const reactionEventName = config.PUSHER_REACTION_EVENT_NAME;
  const liveSaleEndedEventName = config.PUSHER_LIVE_SALE_ENDED_EVENT_NAME;

  // Handle server events
  useEvent<LiveEvent>(liveEventsChannel, eventName, handleEventCallback);

  // Handle client events
  useEvent<LiveEvent>(liveEventsChannel, clientEventName, handleEventCallback);

  // Handle reaction events
  useEvent<ReactionEvent>(
    liveEventsChannel,
    reactionEventName,
    handleReactionCallback
  );

  useEvent<LiveSaleEndedEvent>(
    liveEventsChannel,
    liveSaleEndedEventName,
    handleLiveSaleEndedCallback
  );

  function handleEventCallback(data: LiveEvent | undefined) {
    switch (data?.eventType) {
      case EventType.FacebookComment:
      case EventType.AppComment:
        dispatch(addComment(makeComment(data.payload, customerId)));
        break;
      case EventType.CurrentProduct: {
        const productToAdd = makeProduct(data.payload);
        dispatch(addProduct(productToAdd));
        dispatch(setCurrentProductId(productToAdd.id));
        dispatch(setIsOverlayCleared(false)); // make sure to add the overlay back when they switch products
        break;
      }
      case EventType.UserCount:
        dispatch(setLiveViewerCount(data.payload.userCount));
        break;
      case EventType.ClearProductOverlay:
        dispatch(setIsOverlayCleared(true));
        break;
      case EventType.TrollKick:
        dispatch(trollHasBeenKicked(data.payload));
        break;
      case EventType.AppendCurrentProduct:
        dispatch(setAppendedProduct(makeAppendedProduct(data.payload)));
        break;
    }
  }

  function handleReactionCallback() {
    dispatch(addReaction(makeReaction()));
  }

  function handleLiveSaleEndedCallback() {
    dispatch(setHasLiveSaleEnded(true));
  }
}

// PRIVATE

const makeComment = (
  { commentPayload }: AppCommentEventPayload,
  customerId: number
): Comment => {
  return {
    id: v4(),
    comment: commentPayload.comment,
    userImageUrl: commentPayload.user_image_url,
    userName: commentPayload.username,
    isReplyToMe: commentPayload.to_user_id === customerId,
  };
};

const makeReaction = (): Reaction => {
  return { id: v4() };
};

const makeProduct = (
  currentProductEventPayload: CurrentProductEventPayload
): Product => {
  return {
    type: currentProductEventPayload.productDetails.product_type,
    comments: currentProductEventPayload.productDetails.comments,
    description: currentProductEventPayload.productDetails.description,
    storeDescription:
      currentProductEventPayload.productDetails.store_description,
    formattedColors: currentProductEventPayload.overlayText[2],
    formattedSizes: currentProductEventPayload.overlayText[1],
    id: currentProductEventPayload.productDetails.product_id,
    identifier: currentProductEventPayload.productIdentifier,
    inventory: currentProductEventPayload.productDetails.inventory.map((i) => ({
      ...i,
      id: i.inventory_id,
    })),
    name: currentProductEventPayload.productDetails.product_name,
    priceLabel: currentProductEventPayload.productDetails.price_label,
    quantity: currentProductEventPayload.productDetails.quantity,
    soldText: currentProductEventPayload.overlayText[0],
    style: currentProductEventPayload.productDetails.style,
    thumbnail: currentProductEventPayload.productDetails.thumbnail,
    uniqueId: v4(),
    extraMedia: makeExtraMediaFromStreamInfoFromWebstoreApiProduct(
      currentProductEventPayload.productDetails.extra_media
    ),
    videos: currentProductEventPayload.productDetails.videos.map(
      makeVideoFromVideoFromWebstoreApi
    ),
  };
};

const makeAppendedProduct = (
  payload: AppendCurrentProductEventPayload
): AppendedProduct => {
  return {
    id: payload.appendProductDetails.product_id,
    inventory: makeInventoryFromInventoryFromWebstoreApi(
      payload.appendProductDetails.inventory
    ),
    extraMedia: makeExtraMediaFromStreamInfoFromWebstoreApiProduct(
      payload.appendProductDetails.extra_media
    ),
  };
};
