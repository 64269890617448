import React, { useCallback, useEffect } from 'react';
import {
  pollStreamInfo,
  selectStreamInfo,
} from '@/view/state/stream-info/streamInfoSlice';
import {
  getIsLiveHappening,
  IsLiveHappening,
} from '@/domain/entities/stream-info';
import {
  clearStream,
  getStream,
  selectStream,
} from '@/view/state/stream/streamSlice';
import {
  getReplays,
  selectLatestReplay,
} from '@/view/state/replay/replaySlice';
import { LiveContainer } from '@/view/ui/Live/LiveContainer';
import { useAppDispatch, useAppSelector } from '@/view/state/store';
import { chooseStreamConnection } from '@/view/ui/StreamViewer/helpers/chooseStreamConnection';
import { LiveReplayContainer } from '@/view/ui/Live/LiveReplayContainer';
import { useLotw } from '@/view/context/LotwProvider';
import { selectIsUserBlocked } from '@/view/state/customer/customerSlice';
import { SkeletonLoader } from '@/view/ui/components/organisms/SkeletonLoader';
import { dispatchLOTWEvent } from '@/shared-kernel/events';

const Wrapper: React.FC = ({ children }) => (
  <div className="lotw_container__wrapper">{children}</div>
);

export const StreamViewer: React.FC<{
  autoConnectToReplay?: boolean;
  autoConnectToLive?: boolean;
  errorChildren?: React.ReactNode;
}> = ({
  autoConnectToReplay = true,
  autoConnectToLive = true,
  errorChildren,
}) => {
  const { streamId, onUserBlocked } = useLotw();
  const dispatch = useAppDispatch();
  const {
    streamInfo,
    error: streamInfoError,
    hasLiveSaleEnded,
  } = useAppSelector(selectStreamInfo);
  const {
    stream: selectedStream,
    error: streamError,
    isLoading: isStreamLoading,
  } = useAppSelector(selectStream);
  const latestReplay = useAppSelector(selectLatestReplay);

  const hasError = !!streamInfoError || !!streamError;
  const isLiveHappening = getIsLiveHappening(streamInfo);
  const isUserBlocked = useAppSelector(selectIsUserBlocked);

  useEffect(() => {
    if (isUserBlocked) {
      onUserBlocked?.();
    }
  }, [isUserBlocked, onUserBlocked]);

  useEffect(() => {
    dispatch(pollStreamInfo());
  }, [dispatch, streamId]);

  const streamConnection = chooseStreamConnection({
    selectedStream,
    isLiveHappening:
      autoConnectToLive &&
      !hasLiveSaleEnded &&
      isLiveHappening === IsLiveHappening.Yes,
    isSelectedStreamLive: !!selectedStream?.isLive,
    autoConnectToLive,
  });

  const shouldConnectToLatestReplay = useCallback(() => {
    return (
      // We have the latest replay
      latestReplay &&
      // We want to automatically connect to it
      autoConnectToReplay &&
      // Either we don't want to automatically connect to the live
      (!autoConnectToLive ||
        // Or we do want to but there is not one happening
        (autoConnectToLive && isLiveHappening === IsLiveHappening.No))
    );
  }, [autoConnectToLive, autoConnectToReplay, isLiveHappening, latestReplay]);

  // If we don't have a streamId, and we want to automatically connect to
  // the latest replay, fetch them
  useEffect(() => {
    if (typeof streamId === 'number') return;
    if (!autoConnectToReplay) return;
    dispatch(getReplays(undefined));
  }, [dispatch, streamId, autoConnectToReplay]);

  useEffect(() => {
    // If we have a streamId, just connect to it
    if (typeof streamId === 'number') {
      dispatch(getStream(streamId));
      dispatchLOTWEvent('ConnectToStream', streamId);
      return;
    }

    // If we don't, clear the stream
    dispatch(clearStream());

    // Then connect to the latest replay if we want to
    if (shouldConnectToLatestReplay()) {
      dispatch(getStream(latestReplay.id));
      dispatchLOTWEvent('ConnectToStream', latestReplay.id);
    }
  }, [dispatch, latestReplay, shouldConnectToLatestReplay, streamId]);

  if (isStreamLoading) {
    return (
      <Wrapper>
        <SkeletonLoader />
      </Wrapper>
    );
  }

  if (hasError) {
    return (
      <Wrapper>
        {errorChildren || (
          <div className="lotw-error-msg lotw-tw-py-4 text-red lotw-tw-text-center">
            Sorry, but there was a problem loading this stream. Please try again
            a little later.
          </div>
        )}
      </Wrapper>
    );
  }

  // If we should autoConnectToLive, then we should be sure not to connect to
  // anything else until we know if we are live or not.
  if (autoConnectToLive && isLiveHappening === IsLiveHappening.Unknown) {
    return null;
  }

  if (
    !streamConnection.shouldConnectToLive &&
    !streamConnection.streamToConnectTo
  ) {
    return null;
  }

  return (
    <Wrapper>
      {streamConnection.shouldConnectToLive ? (
        <LiveContainer />
      ) : (
        <LiveReplayContainer replay={streamConnection.streamToConnectTo} />
      )}
    </Wrapper>
  );
};
