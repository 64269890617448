import { useClientTrigger } from '@harelpls/use-pusher';
import { useLiveEventsChannel } from '@/view/context/live-events/LiveEventsChannelContext';
import config from '@/shared-kernel/config';
import { useAppSelector } from '@/view/state/store';
import { selectLotwConfig } from '@/view/state/lotwConfig/lotwConfigSlice';
import {
  EventType,
  LiveEventPayload,
  ReactionEvent,
} from '@/view/hooks/live-events/liveEvents.interface';

export default function useSendLiveEvents() {
  const { shopId } = useAppSelector(selectLotwConfig);
  const liveEventsChannel = useLiveEventsChannel();

  const clientEventName = config.PUSHER_CLIENT_EVENT_NAME;
  const reactionEventName = config.PUSHER_REACTION_EVENT_NAME;
  const clientTrigger = useClientTrigger(liveEventsChannel);

  const sendEvent = (eventType: EventType, payload: LiveEventPayload) => {
    clientTrigger(clientEventName, {
      eventType,
      payload,
      shopId: shopId,
    });
  };

  const sendReactionEvent = (reaction: ReactionEvent) => {
    clientTrigger(reactionEventName, {
      ...reaction,
    });
  };

  return {
    sendEvent,
    sendReactionEvent,
  };
}
