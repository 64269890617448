import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { StreamInfo } from '@/domain/entities/stream-info';
import { RootState } from '@/view/state/store';
import { getContainerInstance } from '@/container';
import { STREAM_INFO_POLLING_INTERVAL } from '@/shared-kernel/constants';
import {
  setCurrentProductId,
  setProducts,
} from '@/view/state/product/productSlice';
import { setComments } from '@/view/state/comment/commentSlice';
import { setReactions } from '@/view/state/reaction/reactionSlice';

export const initialState: {
  isLoading: boolean;
  streamInfo?: StreamInfo;
  hasLiveSaleEnded: boolean;
  error: SerializedError | undefined;
} = {
  isLoading: false,
  streamInfo: undefined,
  hasLiveSaleEnded: false,
  error: undefined,
};

export const getStreamInfo = createAsyncThunk(
  'useCases/streamInfo/getStreamInfo',
  async () => {
    return await getContainerInstance().useCases.getStreamInfo();
  }
);

export const pollStreamInfo = createAsyncThunk(
  'useCases/streamInfo/pollStreamInfo',
  async (_, { dispatch }) => {
    await dispatch(getStreamInfo());

    setTimeout(() => {
      dispatch(pollStreamInfo());
    }, STREAM_INFO_POLLING_INTERVAL);
  }
);

export const initStateFromStreamInfo = createAsyncThunk(
  'useCases/streamInfo/initStateFromStreamInfo',
  async (streamInfo: StreamInfo, { dispatch }) => {
    console.log(
      'Initializing state from streamInfo. This should not happen more than once per streamId.'
    );

    dispatch(setComments([]));
    dispatch(setReactions([]));
    dispatch(setCurrentProductId(streamInfo.products[0]?.id));
    dispatch(setProducts(streamInfo.products));
  }
);

export const streamInfoSlice = createSlice({
  name: 'streamInfo',
  initialState,
  reducers: {
    setHasLiveSaleEnded: (state, action: PayloadAction<boolean>) => {
      state.hasLiveSaleEnded = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStreamInfo.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getStreamInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.streamInfo = action.payload;
      state.error = undefined;
    });

    builder.addCase(getStreamInfo.rejected, (state, action) => {
      state.isLoading = false;
      state.streamInfo = undefined;
      state.error = action.error;
    });
  },
});

export const { setHasLiveSaleEnded } = streamInfoSlice.actions;
export const selectStreamInfo = (state: RootState) => state.streamInfo;
