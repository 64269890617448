interface Comment {
  id: string;
  comment: string;
  shownAt?: number;
  userImageUrl: string;
  userName: string;
  isReplyToMe?: boolean;
}

interface CommentRepository {
  get: (streamId: number) => Promise<Comment[]>;
}

const addComment = (allComments: Comment[], newComment: Comment) => {
  return [...allComments, newComment];
};

const getIsSoldComment = ({ comment }: Comment, soldKeyword?: string) => {
  const commentLowerCase = comment.toLowerCase();
  const soldKeywordLowerCase = soldKeyword?.toLowerCase();

  return (
    commentLowerCase.startsWith('sold') ||
    (!!soldKeywordLowerCase &&
      commentLowerCase.startsWith(soldKeywordLowerCase))
  );
};

const getIdentifierFromCommentText = (
  commentText: string
): string | undefined => {
  const identifierString = commentText.split(/\s+/)[1];
  return identifierString || undefined;
};

export {
  Comment,
  CommentRepository,
  addComment,
  getIsSoldComment,
  getIdentifierFromCommentText,
};
