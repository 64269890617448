import { Product } from '@/domain/entities/product';

interface StreamInfo {
  id: number;
  authUrl: string;
  isStreamingNow: boolean;
  isLotwStreamingEnabled: boolean;
  pusherChannel: string;
  pusherCluster: string;
  pusherKey: string;
  secondsLive: number;
  streamUrl: string;
  products: Product[];
}

interface StreamInfoRepository {
  get: (streamId?: number) => Promise<StreamInfo | undefined>;
}

enum IsLiveHappening {
  Yes = 'Yes',
  No = 'No',
  Unknown = 'Unknown',
}

const getIsLiveHappening = (streamInfo?: StreamInfo): IsLiveHappening => {
  if (!streamInfo) return IsLiveHappening.Unknown;

  return streamInfo.isStreamingNow && streamInfo.isLotwStreamingEnabled
    ? IsLiveHappening.Yes
    : IsLiveHappening.No;
};

export {
  StreamInfo,
  StreamInfoRepository,
  IsLiveHappening,
  getIsLiveHappening,
};
