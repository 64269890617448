export interface StreamInfoFromMarketplaceApi {
  pusher_channel: string;
  pusher_cluster: string;
  pusher_key: string;
  username: string | null;
  user_id: number | null;
  auth_url: string;
  reactions_rate_limit: number;
  reactions_enabled: boolean;
  strikethrough_enabled: boolean;
  stream_server: string;
  current_products: StreamInfoFromMarketplaceApiProduct[];
  overlay_text: [string, string];
  seconds_live: number;
  user_count: number;
  user_count_display: string;
}

export interface StreamInfoFromMarketplaceApiProduct {
  product_id: number;
  product_name: string;
  product_type: string | null;
  description: string;
  store_description: string;
  quantity: number;
  style: string;
  price_label: string;
  badge_label: string | null;
  strikethrough_label: string | null;
  thumbnail: string;
  filename: string;
  videos: VideoFromMarketplaceApi[];
  extra_media: ExtraMediaFromMarketplaceApi[];
  featured_in_live: boolean;
  image_width: number;
  image_height: number;
  inventory: StreamInfoFromMarketplaceApiProductInventoryItem[];
  comments: unknown[];
  sharing: {
    display: string;
    shareTitle: string;
    shareMessage: string;
    shareUrl: string;
  };
  is_favorite: boolean;
  allow_waitlist: boolean;
  split_size: string | null;
  split_color: string | null;
  identifier: string;
}

export interface StreamInfoFromMarketplaceApiProductInventoryItem {
  position: number | null;
  price: number;
  inventory_id: number;
  quantity: number;
  color: string;
  size: string;
}

export type ExtraMediaFromMarketplaceApi =
  | VideoFromMarketplaceApi
  | {
      media_url: string;
    };

export interface VideoFromMarketplaceApi {
  media_url: string;
  media_type: string;
  thumbnail_url: string;
}

export function isVideoFromMarketplaceApi(
  thing: ExtraMediaFromMarketplaceApi
): thing is VideoFromMarketplaceApi {
  // I think this is right.
  return !!(thing as VideoFromMarketplaceApi).thumbnail_url;
}
