import { Reaction, ReactionRepository } from '@/domain/entities/reaction';

interface MakeGetReactionsDeps {
  reactionRepo: ReactionRepository;
}

const makeGetReactions = ({ reactionRepo }: MakeGetReactionsDeps) => {
  const getAllReactions = async (streamId: number) => {
    return await reactionRepo.get(streamId);
  };

  const getReactionsBeforeTimestamp = (
    reactions: Reaction[],
    timestamp: number
  ) => {
    return reactions.filter(
      (reaction) => reaction.shownAt && reaction.shownAt <= timestamp
    );
  };

  return {
    getAllReactions,
    getReactionsBeforeTimestamp,
  };
};

type GetReactions = ReturnType<typeof makeGetReactions>;

export { makeGetReactions, GetReactions };
