import {
  StreamInfoFromWebstoreApi,
  StreamInfoFromWebstoreApiProduct,
  StreamInfoFromWebstoreApiProductInventoryItem,
} from '@/infra/repositories/stream-info/webstore/webstoreStreamInfoRepository.interface';
import { StreamFromWebstoreApiProductInventoryItem } from '@/infra/repositories/stream/webstore/webstoreStreamRepository.interface';
import {
  StreamInfoFromMarketplaceApi,
  StreamInfoFromMarketplaceApiProduct,
  StreamInfoFromMarketplaceApiProductInventoryItem,
} from '@/infra/repositories/stream-info/marketplace/marketplaceStreamInfoRepository.interface';

function buildSizesFromInventory(
  inventory: (
    | StreamFromWebstoreApiProductInventoryItem
    | StreamInfoFromWebstoreApiProductInventoryItem
    | StreamInfoFromMarketplaceApiProductInventoryItem
  )[]
): string {
  const combinedBySize = inventory
    .filter((item) => !!item.size)
    .reduce<Record<string, number>>((acc, curr) => {
      const currSize = curr.size!; // Can't be null due to the filter above
      acc[currSize] = (acc[currSize] || 0) + curr.quantity;

      return acc;
    }, {});

  return combinedBySize ? buildString(combinedBySize, 'Sizes: ') : '';
}

function buildColorsFromInventory(
  inventory: (
    | StreamFromWebstoreApiProductInventoryItem
    | StreamInfoFromWebstoreApiProductInventoryItem
    | StreamInfoFromMarketplaceApiProductInventoryItem
  )[]
): string {
  const combinedByColor = inventory
    .filter((item) => !!item.color)
    .reduce<Record<string, number>>((acc, curr) => {
      const currColor = curr.color!; // Can't be null due to the filter above
      acc[currColor] = (acc[currColor] || 0) + curr.quantity;

      return acc;
    }, {});

  return combinedByColor ? buildString(combinedByColor, 'Colors: ') : '';
}

const makePathWithBaseUrl = (baseUrl: string, path: string) => {
  return `${baseUrl}${path}`;
};

// buildOverlay
interface BuildOverlayParams {
  streamInfo: StreamInfoFromWebstoreApi | StreamInfoFromMarketplaceApi;
  productFromWebstoreApi:
    | StreamInfoFromWebstoreApiProduct
    | StreamInfoFromMarketplaceApiProduct;
}

interface OverlayObject {
  soldText: string;
  formattedColors: string;
  formattedSizes: string;
}

function buildOverlay({
  streamInfo,
  productFromWebstoreApi,
}: BuildOverlayParams): OverlayObject {
  return {
    soldText: streamInfo.overlay_text[0],
    formattedSizes: buildSizesFromInventory(productFromWebstoreApi.inventory),
    formattedColors: buildColorsFromInventory(productFromWebstoreApi.inventory),
  };
}
// END buildOverlay

export {
  buildSizesFromInventory,
  buildColorsFromInventory,
  makePathWithBaseUrl,
  buildOverlay,
};

// PRIVATE

const buildString = (obj: Record<string, unknown>, prefix: string) =>
  Object.entries(obj)
    .filter(([item]) => item)
    // eslint-disable-next-line max-params
    .reduce<string>((theString, [item, quantity], currentIndex, orig) => {
      const lastIndex = orig.length - 1;
      const isLastIndex = currentIndex === lastIndex;

      if (!quantity) {
        // Markdown interpreter makes this a strikethrough
        item = `~~${item}~~`;
      }

      const withoutComma = theString + item;
      const withComma = withoutComma + ', ';
      const returnString = isLastIndex ? withoutComma : withComma;

      if (currentIndex === 0) {
        return prefix + returnString;
      } else {
        return returnString;
      }
    }, '');
