interface Reaction {
  id: string;
  shownAt?: number;
}

interface ReactionRepository {
  get: (streamId: number) => Promise<Reaction[]>;
}

const addReaction = (allReactions: Reaction[], newReaction: Reaction) => {
  return [...allReactions, newReaction];
};

export { Reaction, ReactionRepository, addReaction };
