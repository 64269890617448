import { HttpService } from '@/infra/services/http/httpService';
import {
  StreamInfo,
  StreamInfoRepository,
} from '@/domain/entities/stream-info';
import {
  ExtraMediaFromMarketplaceApi,
  isVideoFromMarketplaceApi,
  StreamInfoFromMarketplaceApi,
  StreamInfoFromMarketplaceApiProductInventoryItem,
  VideoFromMarketplaceApi,
} from '@/infra/repositories/stream-info/marketplace/marketplaceStreamInfoRepository.interface';
import { StreamFromMarketplaceApi } from '@/infra/repositories/stream/marketplace/marketplaceStreamRepository.interface';
import { ExtraMedia, Product, Video } from '@/domain/entities/product';
import { buildOverlay } from '@/infra/repositories/helpers';
import { v4 } from 'uuid';

const makeMarketplaceStreamInfoRepository = ({
  httpService,
  shopId,
  streamId,
}: {
  httpService: HttpService;
  shopId: string;
  streamId: number;
}): StreamInfoRepository => {
  const get = async () => {
    const streamFromMarketplaceApi =
      await httpService.get<StreamFromMarketplaceApi>({
        url: `${shopId}/streams/${streamId}`,
      });

    if (!streamFromMarketplaceApi?.stream_info) return undefined;

    return makeStreamInfoFromStreamInfoFromMarketplaceApi({
      streamInfoFromMarketplaceApi: streamFromMarketplaceApi.stream_info,
      streamId: streamId,
      sourceUrl: streamFromMarketplaceApi.source_url,
    });
  };

  return Object.freeze({
    get,
  });
};

export { makeMarketplaceStreamInfoRepository };

// The repository should convert API responses into domain entities
// We should not use the API entities directly
export function makeStreamInfoFromStreamInfoFromMarketplaceApi({
  streamInfoFromMarketplaceApi,
  streamId,
  sourceUrl,
}: {
  streamInfoFromMarketplaceApi: StreamInfoFromMarketplaceApi;
  streamId: number;
  sourceUrl: string;
}): StreamInfo {
  return {
    id: streamId,
    authUrl: streamInfoFromMarketplaceApi.auth_url,
    isStreamingNow: true, // From this endpoint, stream_info itself is null if the shop isn't live
    isLotwStreamingEnabled: true, // In Marketplace, we always want to see the stream and don't disable it
    pusherChannel: streamInfoFromMarketplaceApi.pusher_channel,
    pusherKey: streamInfoFromMarketplaceApi.pusher_key,
    pusherCluster: streamInfoFromMarketplaceApi.pusher_cluster,
    secondsLive: streamInfoFromMarketplaceApi.seconds_live,
    streamUrl: sourceUrl,
    products: createProductsFromStreamInfo(streamInfoFromMarketplaceApi),
  };
}

export const createProductsFromStreamInfo = (
  streamInfoFromMarketplaceApi: StreamInfoFromMarketplaceApi
): Product[] => {
  return streamInfoFromMarketplaceApi.current_products.map((p) => {
    return {
      ...buildOverlay({
        streamInfo: streamInfoFromMarketplaceApi,
        productFromWebstoreApi: p,
      }),
      comments: p.comments,
      description: p.description,
      storeDescription: p.store_description,
      extraMedia: makeExtraMedia(p.extra_media),
      id: p.product_id,
      identifier: p.identifier,
      inventory: makeInventoryFromInventoryFromMarketplaceApi(p.inventory),
      name: p.product_name,
      priceLabel: p.price_label,
      quantity: p.quantity,
      style: p.style,
      thumbnail: p.thumbnail,
      type: p.product_type || '',
      uniqueId: v4(),
      videos: p.videos.map(makeVideoFromVideoFromMarketplaceApi),
    };
  });
};

const makeInventoryFromInventoryFromMarketplaceApi = (
  inventoryFromMarketplaceApi: StreamInfoFromMarketplaceApiProductInventoryItem[]
) => {
  return inventoryFromMarketplaceApi.map((i) => {
    return { ...i, id: i.inventory_id };
  });
};

const makeExtraMedia = (
  extraMediaFromWebstoreApi: ExtraMediaFromMarketplaceApi[]
): ExtraMedia[] => {
  return extraMediaFromWebstoreApi.map((em) => {
    if (isVideoFromMarketplaceApi(em)) {
      return makeVideoFromVideoFromMarketplaceApi(em);
    } else {
      return {
        mediaUrl: em.media_url,
      };
    }
  });
};

const makeVideoFromVideoFromMarketplaceApi = (
  videoFromWebstoreApi: VideoFromMarketplaceApi
): Video => {
  return {
    mediaUrl: videoFromWebstoreApi.media_url,
    mediaType: videoFromWebstoreApi.media_type,
    thumbnailUrl: videoFromWebstoreApi.thumbnail_url,
  };
};
