import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const LiveProductInfoSkeletonLoader: React.FC = () => (
  <div className="lotw-tw-flex lotw-tw-border-t-1 lotw-tw-mb-4">
    <Skeleton
      className="lotw-tw-h-20 lotw-tw-w-20"
      containerClassName="lotw-tw-block lotw-tw-m-4"
    />
    <div className="lotw-tw-flex-1 lotw-tw-mt-2">
      <Skeleton
        className="lotw-tw-h-3 lotw-tw-w-1/3"
        containerClassName="lotw-tw-block lotw-tw-my-2"
      />
      <Skeleton
        className="lotw-tw-h-3 lotw-tw-w-2/3"
        containerClassName="lotw-tw-block lotw-tw-my-1"
      />
      <Skeleton
        className="lotw-tw-h-3 lotw-tw-w-1/2"
        containerClassName="lotw-tw-block lotw-tw-my-1"
      />
    </div>
  </div>
);
