import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/view/state/store';
import { Comment } from '@/domain/entities/comment';
import { getContainerInstance } from '@/container';
import { Reaction } from '@/domain/entities/reaction';
import { Product } from '@/domain/entities/product';

export const initialState: {
  currentVideoTime: number;
  scrubToTime: number;
} = {
  currentVideoTime: 0, // Updates as video plays
  scrubToTime: 0, // Video will scrub to this when changed
};

export const setCurrentVideoTime = createAsyncThunk<
  number,
  number,
  { state: RootState }
>('useCases/video/setCurrentVideoTime', (timestamp, { getState, dispatch }) => {
  if (!getState().misc.isLive) {
    dispatch(setStateByTimestamp(timestamp));
  }

  return timestamp;
});

export const setStateByTimestamp = createAsyncThunk<
  {
    commentsBeforeTimestamp: Comment[];
    reactionsBeforeTimestamp: Reaction[];
    productsBeforeTimestamp: Product[];
  },
  number,
  { state: RootState }
>('useCases/video/setStateByTimestamp', (timestamp, { getState }) => {
  const container = getContainerInstance();
  const comments = getState().comment.comments;
  const reactions = getState().reaction.reactions;
  const products = getState().product.products;

  const commentsBeforeTimestamp = container.useCases.getCommentsBeforeTimestamp(
    comments,
    timestamp
  );
  const reactionsBeforeTimestamp =
    container.useCases.getReactionsBeforeTimestamp(reactions, timestamp);
  const productsBeforeTimestamp = container.useCases.getProductsBeforeTimestamp(
    products,
    timestamp
  );

  return {
    timestamp,
    commentsBeforeTimestamp,
    reactionsBeforeTimestamp,
    productsBeforeTimestamp,
  };
});

export const videoSlice = createSlice({
  name: 'live',
  initialState,
  reducers: {
    setScrubToTime: (state, { payload }: PayloadAction<number>) => {
      state.scrubToTime = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentVideoTime.fulfilled, (state, action) => {
      state.currentVideoTime = action.payload;
    });
  },
});

export const { setScrubToTime } = videoSlice.actions;
export const selectCurrentVideoTime = (state: RootState) =>
  state.video.currentVideoTime;
export const selectScrubToTime = (state: RootState) => state.video.scrubToTime;
