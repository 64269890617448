import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  streamInfoSlice,
  initialState as streamInfoInitialState,
} from '@/view/state/stream-info/streamInfoSlice';
import {
  streamSlice,
  initialState as streamInitialState,
} from '@/view/state/stream/streamSlice';
import {
  replaySlice,
  initialState as replayInitialState,
} from '@/view/state/replay/replaySlice';
import {
  lotwConfigSlice,
  initialState as lotwConfigInitialState,
} from '@/view/state/lotwConfig/lotwConfigSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  commentSlice,
  initialState as commentInitialState,
} from '@/view/state/comment/commentSlice';
import {
  reactionSlice,
  initialState as reactionInitialState,
} from '@/view/state/reaction/reactionSlice';
import {
  productSlice,
  initialState as productInitialState,
} from '@/view/state/product/productSlice';
import {
  miscSlice,
  initialState as miscInitialState,
} from '@/view/state/misc/miscSlice';
import {
  videoSlice,
  initialState as videoInitialState,
} from '@/view/state/video/videoSlice';
import {
  customerSlice,
  initialState as customerInitialState,
} from '@/view/state/customer/customerSlice';

const combinedReducers = combineReducers({
  lotwConfig: lotwConfigSlice.reducer,
  streamInfo: streamInfoSlice.reducer,
  stream: streamSlice.reducer,
  replay: replaySlice.reducer,
  comment: commentSlice.reducer,
  reaction: reactionSlice.reducer,
  product: productSlice.reducer,
  misc: miscSlice.reducer,
  video: videoSlice.reducer,
  customer: customerSlice.reducer,
});

// Spent an hour trying to type this. Tricky.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'reset') {
    state = {
      lotwConfig: lotwConfigInitialState,
      streamInfo: streamInfoInitialState,
      stream: {
        ...streamInitialState,
        isLoading: state.stream.isLoading,
        isMuted: state.stream.isMuted,
      },
      replay: replayInitialState,
      comment: commentInitialState,
      reaction: reactionInitialState,
      product: productInitialState,
      misc: miscInitialState,
      video: videoInitialState,
      customer: customerInitialState,
    };
  }

  return combinedReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
