export interface StreamInfoFromWebstoreApi {
  authURL: string;
  currentProduct: StreamInfoFromWebstoreApiProduct[];
  is_streaming_now: boolean;
  isLotwStreamingEnabled: boolean;
  live_sale_id: number;
  overlay_text: string[];
  pusherChannel: string;
  pusherCluster: string;
  pusherKey: string;
  reactions_enabled: boolean;
  reactions_rate_limit: number;
  secondsLive: number;
  sharing: {
    display: string;
    shareMessage: string;
    shareTitle: string;
    shareUrl: string;
  };
  streamServer: string;
  stream_url: string;
  strikethrough_enabled: boolean;
  success: boolean;
  user_count: number;
  user_count_display: string;
  user_id: number;
  user_image_url: string;
  username: string;
  influencerSlug: string;
}

export interface StreamInfoFromWebstoreApiProduct {
  product_id: number;
  product_name: string;
  product_path: string;
  product_type: string;
  description: string;
  store_description: string;
  quantity: number;
  style: string;
  price_label: string;
  badge_label: unknown;
  strikethrough_label: unknown;
  thumbnail: string;
  filename: string;
  videos: VideoFromWebstoreApi[];
  extra_media: ExtraMediaFromWebstoreApi[];
  featured_in_live: boolean;
  image_width: number;
  image_height: number;
  inventory: StreamInfoFromWebstoreApiProductInventoryItem[];
  comments: unknown[];
  sharing: {
    display: string;
    shareTitle: string;
    shareMessage: string;
    shareUrl: string;
  };
  is_favorite: boolean;
  allow_waitlist: boolean;
  identifier: string;
  sold_text: string;
  external_id: string;
}

export interface StreamInfoFromWebstoreApiProductInventoryItem {
  position: number | null;
  price: number;
  inventory_id: number;
  quantity: number;
  color: string;
  size: string;
}

export type ExtraMediaFromWebstoreApi =
  | VideoFromWebstoreApi
  | {
      media_url: string;
    };

export interface VideoFromWebstoreApi {
  media_url: string;
  media_type: string;
  thumbnail_url: string;
}

export function isVideoFromWebstoreApi(
  thing: ExtraMediaFromWebstoreApi
): thing is VideoFromWebstoreApi {
  // I think this is right.
  return !!(thing as VideoFromWebstoreApi).thumbnail_url;
}
