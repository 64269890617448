import React, { FC, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@/view/state/store';
import { LiveEventsProvider } from '@/view/context/live-events/LiveEventsProvider';
import { Product } from '@/domain/entities/product';
import {
  selectCurrentProduct,
  selectProducts,
  selectSelectedProduct,
  setSelectedProductId,
} from '@/view/state/product/productSlice';
import {
  initStateFromStreamInfo,
  selectStreamInfo,
} from '@/view/state/stream-info/streamInfoSlice';
import { selectViewerCount } from '@/view/state/misc/miscSlice';
import { Live } from '@/view/ui/Live/Live';
import { useLotw } from '@/view/context/LotwProvider';
import { selectCustomer } from '@/view/state/customer/customerSlice';

export const LiveContainer: FC = () => {
  return (
    <LiveEventsProvider>
      <LiveContainerInner />
    </LiveEventsProvider>
  );
};

const LiveContainerInner: FC = () => {
  const streamIdRef = useRef<number>();
  const { onConnectToStream } = useLotw();
  const dispatch = useAppDispatch();

  const selectedProduct = useAppSelector(selectSelectedProduct);
  const currentProduct = useAppSelector(selectCurrentProduct);
  const allProducts = useAppSelector(selectProducts);
  const userCount = useAppSelector(selectViewerCount);
  const { streamInfo } = useAppSelector(selectStreamInfo);
  const { id: customerId } = useAppSelector(selectCustomer);

  useEffect(() => {
    // Only continue if we have a streamId, and it is different from the previous one
    if (!streamInfo?.id || streamInfo?.id === streamIdRef.current) return;

    streamIdRef.current = streamInfo?.id;

    dispatch(initStateFromStreamInfo(streamInfo));
    onConnectToStream?.({ streamId: streamInfo.id, isLive: true });
  }, [streamInfo, onConnectToStream, dispatch]);

  const handleViewProduct = (product?: Product): void => {
    dispatch(setSelectedProductId(product?.id));
  };

  if (!streamInfo) return null;

  return (
    <Live
      currentProduct={currentProduct}
      elapsedTimeWhenMounted={streamInfo.secondsLive}
      isLive={streamInfo.isStreamingNow}
      onCloseSelectedProduct={handleViewProduct}
      onViewProduct={handleViewProduct}
      allProducts={allProducts}
      selectedProduct={selectedProduct}
      streamUrl={streamInfo.streamUrl || ''}
      viewers={userCount}
      userId={customerId}
    />
  );
};
