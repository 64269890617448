import React from 'react';
import { useAppSelector } from '@/view/state/store';
import { Chat } from '@/view/ui/Chat/Chat';
import { selectCommentsBeforeTimestamp } from '@/view/state/comment/commentSlice';
import { selectReactionsBeforeTimestamp } from '@/view/state/reaction/reactionSlice';

interface ChatReplayContainerProps {
  expirationTime?: string;
  onShopAll: () => void;
  isInOverlay?: boolean;
  isDisabled?: boolean;
  isMobile?: boolean;
  isLive: boolean;
  isReadOnly: boolean;
}

const ChatReplayContainer: React.FC<ChatReplayContainerProps> = ({
  onShopAll,
  isDisabled,
  isInOverlay,
  isMobile,
  isLive,
  isReadOnly,
}) => {
  const comments = useAppSelector(selectCommentsBeforeTimestamp);
  const reactions = useAppSelector(selectReactionsBeforeTimestamp);

  return (
    <Chat
      comments={comments}
      reactions={reactions}
      isDisabled={isDisabled}
      onShopAll={onShopAll}
      isInOverlay={isInOverlay}
      isMobile={isMobile}
      isLive={isLive}
      areReactionsEnabled={true}
      isReadOnly={isReadOnly}
    />
  );
};

export { ChatReplayContainer };
