import React, { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './Button.module.css';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  fullWidth?: boolean;
  label?: string;
  primary?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  fullWidth,
  label,
  primary,
  disabled,
  ...props
}) => (
  <button
    type={'button' || props.type}
    className={cn(
      styles.root,
      {
        [styles.primary]: primary,
        [styles.fullWidth]: fullWidth,
        [styles.disabled]: disabled,
      },
      className
    )}
    disabled={disabled}
    {...props}
  >
    {label || children}
  </button>
);

export default Button;
