import React from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
interface ShowingNowSkeletonLoader {
  width: number;
}
export const ShowingNowSkeletonLoader: React.FC<ShowingNowSkeletonLoader> = ({
  width,
}) => (
  <div
    className={cn(
      width >= 400 && 'card--tablet',
      width >= 768 && 'card--desktop',
      'lotw-p-2'
    )}
  >
    <Skeleton
      className="lotw-tw-h-4 lotw-tw-w-1/3"
      containerClassName="lotw-tw-m-4 lotw-tw-block"
    />
    <Skeleton
      className="lotw-tw-h-3 lotw-tw-w-2/3"
      containerClassName="lotw-tw-m-4 lotw-tw-block"
    />
  </div>
);
