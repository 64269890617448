import { constrain } from '@/utils/math';
import React, { FormEvent, useEffect, useState } from 'react';
import styles from './QuantityField.module.css';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';

export interface QuantityFieldProps {
  defaultValue?: number;
  min?: number;
  max?: number;
  step?: number;
  onChange: (value: number) => void;
}

const QuantityField: React.FC<QuantityFieldProps> = ({
  defaultValue,
  min,
  max,
  step = 1,
  onChange,
}) => {
  const [value, setValue] = useState(constrain(defaultValue || 0, min, max));
  const [rawValue, setRawValue] = useState<string>(String(value));

  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setRawValue(e.currentTarget.value);
  };

  const handleBlur = (e: FormEvent<HTMLInputElement>) => {
    const newValue = constrain(e.currentTarget.value, min, max);

    if (!isNaN(newValue)) {
      setValue(newValue);
      setRawValue(String(newValue));
    }
  };

  const decrementValue = () => {
    const newValue = value - Number(step);

    if (!min || newValue >= Number(min)) {
      setValue(newValue);
      setRawValue(String(newValue));
    }
  };

  const incrementValue = () => {
    const newValue = value + Number(step);

    if (!max || newValue <= Number(max)) {
      setValue(newValue);
      setRawValue(String(newValue));
    }
  };

  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        className={styles.buttonLeft}
        onClick={() => decrementValue()}
      >
        <AiOutlineMinus />
      </button>
      <input
        type="text"
        value={rawValue}
        onChange={handleChange}
        onBlur={handleBlur}
        className={styles.input}
      />
      <button
        type="button"
        className={styles.buttonRight}
        onClick={() => incrementValue()}
      >
        <AiOutlinePlus />
      </button>
    </div>
  );
};

export default QuantityField;
