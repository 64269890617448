import React, { useRef } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useElementSize from '@/view/hooks/useElementSize';
import cn from 'classnames';
import { ShowingNowSkeletonLoader } from '@/view/ui/components/molecules/SkeletonLoaders/ShowingNowSkeletonLoader';
import { LiveProductInfoSkeletonLoader } from '@/view/ui/components/molecules/SkeletonLoaders/LiveProductInfoSkeletonLoader';
import { ChatContainerSkeletonLoader } from '@/view/ui/components/molecules/SkeletonLoaders/ChatContainerSkeletonLoader';

export const SkeletonLoader: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { width } = useElementSize(wrapperRef);

  return (
    <SkeletonTheme baseColor={'#f5f5f5'} highlightColor={'#fcfcfc'}>
      <Skeleton
        className="lotw-tw-h-5 lotw-tw-w-1/4"
        containerClassName={cn('lotw-tw-my-4 lotw-tw-hidden md:lotw-tw-block')}
      />
      <div
        className="live-component__constrainer"
        style={{
          gridTemplateColumns:
            width > 1200 ? 'auto 1fr 1fr' : width >= 768 ? 'auto 1fr' : 'auto',
          height: width >= 768 ? '709px' : '500px',
        }}
        ref={wrapperRef}
      >
        <div
          className={cn(
            'lotw-tw-w-full lotw-tw-overflow-hidden lotw-tw-relative',
            // eslint-disable-next-line sonarjs/no-duplicate-string
            width >= 400 && 'card--tablet',
            // eslint-disable-next-line sonarjs/no-duplicate-string
            width >= 768 && 'card--desktop',
            'skeleton-loader__video'
          )}
          style={{ maxWidth: 400, width }}
        >
          <Skeleton className="lotw-tw-w-full lotw-tw-h-full" />
        </div>
        <div
          className={cn(
            'live-component__product-container lotw-tw-relative',
            width >= 768 &&
              'lotw-tw-flex lotw-tw-flex-col lotw-tw-justify-items-stretch'
          )}
          style={{ minHeight: width >= 768 ? '709px' : '500px' }}
        >
          <ShowingNowSkeletonLoader width={width} />
          <div
            className={cn(
              'lotw-tw-mt-2 lotw-tw-flex-1 lotw-tw-relative',
              width >= 400 && 'card--tablet',
              width >= 768 && 'card--desktop'
            )}
          >
            <Skeleton
              className="lotw-tw-h-4 lotw-tw-w-1/2"
              containerClassName="lotw-tw-block lotw-tw-m-4"
            />
            <LiveProductInfoSkeletonLoader />
            <LiveProductInfoSkeletonLoader />
            <LiveProductInfoSkeletonLoader />
            <LiveProductInfoSkeletonLoader />
          </div>
        </div>

        <ChatContainerSkeletonLoader width={width} />
      </div>
    </SkeletonTheme>
  );
};
