import { InventoryItem } from '@/domain/entities/product';

export type OptionGroup = Record<
  string,
  { quantity: number; variantExists: boolean }
>;

export interface Options {
  sizes: OptionGroup;
  colors: OptionGroup;
  singleOption?: number;
}

export default function createOptions({
  inventoryItems,
  selectedSize,
  selectedColor,
}: {
  inventoryItems: InventoryItem[];
  selectedSize?: string;
  selectedColor?: string;
}): Options {
  if (
    inventoryItems.length === 1 &&
    !inventoryItems[0].size &&
    !inventoryItems[0].color
  ) {
    return {
      sizes: {},
      colors: {},
      singleOption: inventoryItems[0].quantity,
    };
  }

  return {
    sizes: reduceInventory({
      items: inventoryItems,
      sizeOrColor: 'size',
      selectedSize: selectedSize,
      selectedColor: selectedColor,
    }),
    colors: reduceInventory({
      items: inventoryItems,
      sizeOrColor: 'color',
      selectedSize: selectedSize,
      selectedColor: selectedColor,
    }),
  };
}

function reduceInventory({
  items,
  sizeOrColor,
  selectedSize,
  selectedColor,
}: {
  items: InventoryItem[];
  sizeOrColor: 'size' | 'color';
  selectedSize?: string;
  selectedColor?: string;
}): OptionGroup {
  return items.reduce<OptionGroup>((acc, item) => {
    const otherSizeOrColor = sizeOrColor === 'size' ? 'color' : 'size';

    // Don't use empty string as a prop name
    if (item[sizeOrColor] === '') return acc;

    if (!acc[item[sizeOrColor]]) {
      const quantity = addQuantityIfSelected({
        item,
        sizeOrColor: otherSizeOrColor,
        selectedSize,
        selectedColor,
      });

      if (quantity !== undefined) {
        acc[item[sizeOrColor]] = { quantity, variantExists: true };
      } else {
        acc[item[sizeOrColor]] = { quantity: 0, variantExists: false };
      }
    } else {
      const quantity = addQuantityIfSelected({
        item,
        sizeOrColor: otherSizeOrColor,
        selectedSize,
        selectedColor,
      });

      if (quantity !== undefined) {
        acc[item[sizeOrColor]].quantity += quantity;
        acc[item[sizeOrColor]].variantExists = true;
      }
    }

    return acc;
  }, {});
}

function addQuantityIfSelected({
  item,
  sizeOrColor,
  selectedSize,
  selectedColor,
}: {
  item: InventoryItem;
  sizeOrColor: 'size' | 'color';
  selectedSize?: string;
  selectedColor?: string;
}): number | undefined {
  const selectedSizeOrColor =
    sizeOrColor === 'size' ? selectedSize : selectedColor;

  // If there is no selection, add the quantity
  // If there is a selection, only add the quantity if it matches this item
  if (!selectedSizeOrColor || selectedSizeOrColor === item[sizeOrColor]) {
    return item.quantity;
  } else {
    return undefined;
  }
}
