import React from 'react';
import cn from 'classnames';
import styles from './SectionHeading.module.css';

export interface SectionHeadingProps {
  className?: string;
  light?: boolean;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({
  children,
  className,
  light,
  ...props
}) => {
  return (
    <h3
      className={cn(
        styles.root,
        {
          [styles.light]: light,
        },
        className
      )}
      {...props}
    >
      {children}
    </h3>
  );
};

export default SectionHeading;
