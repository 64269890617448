import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/view/state/store';

export const initialState: {
  liveViewerCount: number | undefined;
  isOverlayCleared: boolean;
  isLive: boolean;
} = {
  liveViewerCount: undefined,
  isOverlayCleared: false,
  isLive: false,
};

export const miscSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setLiveViewerCount: (state, action: PayloadAction<number>) => {
      state.liveViewerCount = action.payload;
    },
    setIsOverlayCleared: (state, action: PayloadAction<boolean>) => {
      state.isOverlayCleared = action.payload;
    },
    setIsLive: (state, action: PayloadAction<boolean>) => {
      state.isLive = action.payload;
    },
  },
});

export const { setLiveViewerCount, setIsOverlayCleared, setIsLive } =
  miscSlice.actions;
export const selectViewerCount = (state: RootState) =>
  state.misc.liveViewerCount != null
    ? state.misc.liveViewerCount
    : state.stream.stream?.peakViewers || 0;
export const selectIsLive = (state: RootState) => state.misc.isLive;
export const selectIsOverlayCleared = (state: RootState) =>
  state.misc.isOverlayCleared;
